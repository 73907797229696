import { UsersIcon, PencilSquareIcon, GlobeAltIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline';

import Select from 'react-select';

import { Input } from '../../../common/input.tsx';
import { Form, FormField, FormItem, FormControl, FormMessage } from '../../../common/form.tsx';
import { Switch } from '../../../common/switch.tsx';

export const ReceivingChargeType = [
    {
        label: 'per Item Rate',
        value: 'PER_ITEM',
    },
];

const ReceivingFeeForm = ({ formProps, customerData }) => (
    <Form {...formProps}>
        <form className="space-y-8">
            <div className="pt-4">
                <div className="flex text-xl font-medium">
                    <PencilSquareIcon className="h-6 w-6 mr-2" />
                    Name
                </div>
                <FormField
                    control={formProps.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input className="mt-2" placeholder="Enter pricing rule name" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-medium">
                    <GlobeAltIcon className="h-6 w-6 mr-2" />
                    For all customers
                </div>
                <div className="mt-2 flex items-center space-x-4">
                    <FormField
                        control={formProps.control}
                        name="all_customers"
                        render={({ field }) => (
                            <>
                                <FormItem>
                                    <FormControl>
                                        <Switch onCheckedChange={field.onChange} checked={field.value} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                                <span>Apply to all customers?</span>
                            </>
                        )}
                    />
                </div>
            </div>
            {!formProps.getValues('all_customers') && (
                <div className="pt-4">
                    <div className="flex text-xl font-medium">
                        <UsersIcon className="h-6 w-6 mr-2" />
                        Customers
                    </div>
                    <div className="mt-2">
                        <FormField
                            control={formProps.control}
                            name="customer_ids"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Select
                                            {...field}
                                            isMulti
                                            options={customerData}
                                            placeholder="Customer Names"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DBF3D8',
                                                    primary: '#92BAA3',
                                                },
                                            })}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
            )}
            <div className="pt-4">
                <div className="flex text-xl font-medium">
                    <CurrencyDollarIcon className="h-6 w-6 mr-2" />
                    Charging Rates
                </div>
                <div className="mt-2">
                    <FormField
                        control={formProps.control}
                        name="charge_rate_type"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Select
                                        {...field}
                                        options={ReceivingChargeType}
                                        placeholder="Charging Rate Type"
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#DBF3D8',
                                                primary: '#92BAA3',
                                            },
                                        })}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                {formProps.watch('charge_rate_type')?.value === 'PER_ITEM' && (
                    <div className="mt-2">
                        <span>Per unit of item received.</span>
                        <FormField
                            control={formProps.control}
                            name="charge_amount"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input className="mt-2" placeholder="0" type="number" {...field} prefix="$" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                )}
            </div>
        </form>
    </Form>
);

export default ReceivingFeeForm;
