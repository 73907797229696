import * as z from 'zod';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetFooter } from '../../common/sheet.tsx';
import EditChargeForm from './forms/invoiceEditChargeForm';
import LoadingSpinner from '../../common/loadingSpinner';
import useInvoiceApi from '../../../hooks/api/useInvoiceApi';

const InvoiceEditChargeView = ({ isOpen, setIsOpen, invoiceId, lineItemId, invoiceData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { updateLineItemManually } = useInvoiceApi();

    // Define the schema with zod
    const invoiceChargeSchema = z.object({
        old_amount: z.string(),
        charge_type_name: z.string().nullable(),
        amount: z.number().min(0, { message: 'Required' }),
    });

    const defaultValues = {
        old_amount: invoiceData?.amount || '0',
        charge_type_name: invoiceData?.charge_type_name || null,
        amount: invoiceData?.amount ? parseFloat(invoiceData.amount) : 0,
    };

    // Initialize the form with useForm and default values
    const formProps = useForm({
        resolver: zodResolver(invoiceChargeSchema),
        defaultValues,
    });

    const { handleSubmit, reset } = formProps;

    const onSubmit = (values) => {
        setIsLoading(true);

        updateLineItemManually(invoiceId, lineItemId, values.amount).then((res) => {
            if (res.success) {
                setIsLoading(false);
                window.location.reload();
            }
        });
    };

    useEffect(() => {
        reset(defaultValues); // Reset form with default values whenever invoiceData changes
    }, [invoiceData, reset]);

    return (
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
            <SheetContent position="right" size="lg">
                <SheetHeader className="pb-36 overflow-y-auto h-full">
                    <SheetTitle className="text-3xl">Edit {invoiceData?.charge_type_name} Charge</SheetTitle>
                    <SheetDescription className="text-rails-dark-blue">
                        Update {invoiceData?.charge_type_name} charge for invoice #{invoiceId}
                    </SheetDescription>
                    <EditChargeForm formProps={formProps} />
                </SheetHeader>
                <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                    <div>
                        <Button disabled={isLoading} onClick={handleSubmit(onSubmit)} type="submit">
                            {isLoading && (
                                <>
                                    <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                                    Updating
                                </>
                            )}
                            {!isLoading && <>Update</>}
                        </Button>
                    </div>
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
};

export default InvoiceEditChargeView;
