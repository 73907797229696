import { useRef, useState } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { useAuth } from '../../context/AuthContext';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '../common/alert-dialog.tsx';
import LoadingSpinner from '../common/loadingSpinner';
import { useToast } from '../common/use-toast.tsx';

const ResetPassword = ({ open, setOpen }) => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfRef = useRef();
    const oldpasswordRef = useRef();
    const { updatePassword } = useAuth();
    const [notification, setNotification] = useState('');
    const [loading, setLoading] = useState(false);
    const { toast } = useToast();

    const handleSubmit = (event) => {
        event.preventDefault();

        setLoading(true);
        setNotification('');

        if (passwordRef.current.value.length < 8) {
            return setNotification('Password must at least have eight characters');
        }

        if (passwordRef.current.value !== passwordConfRef.current.value) {
            return setNotification('Password does not match');
        }

        const promises = [];

        if (passwordRef.current.value) {
            promises.push(updatePassword(oldpasswordRef.current.value, passwordRef.current.value));
        }

        Promise.all(promises)
            .then(() => {
                setLoading(false);
                setOpen(false);
            })
            .catch(() => {
                setLoading(false);
                setOpen(false);
                toast({
                    description: (
                        <span className="flex text-lg">
                            <ExclamationTriangleIcon className="mt-0.5 mr-4 h-6 w-6 text-red-800 font-bold" /> We encountered an error. Please try again.
                        </span>
                    ),
                });
            });
        return true;
    };

    return (
        <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Reset password</AlertDialogTitle>
                    <AlertDialogDescription>
                        <form
                            onSubmit={() => {
                                handleSubmit();
                            }}
                            className="mt-2 flex flex-col"
                        >
                            {notification && (
                                <span className="mb-4 w-80">
                                    <h2 className="text-rails-dark-blue font-poppinsReg text-sm">{notification}</h2>
                                </span>
                            )}
                            <input
                                ref={emailRef}
                                placeholder="Confirm email"
                                required
                                type="email"
                                className="mb-3 py-2 px-2 border focus:outline-none rounded-md font-poppinsReg"
                            />
                            <input
                                type="password"
                                ref={oldpasswordRef}
                                placeholder="Current password"
                                required
                                className="mb-3 py-2 px-2 border focus:outline-none rounded-md font-poppinsReg"
                            />
                            <input
                                type="password"
                                ref={passwordRef}
                                placeholder="New password"
                                required
                                className="mb-3 py-2 px-2 border focus:outline-none rounded-md  font-poppinsReg"
                            />
                            <input
                                type="password"
                                ref={passwordConfRef}
                                required
                                placeholder="Confirm new password"
                                className="mb-6 py-2 px-2 border focus:outline-none rounded-md  font-poppinsReg"
                            />
                        </form>
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={(e) => handleSubmit(e)} disabled={loading}>
                        {loading && (
                            <>
                                <LoadingSpinner loading={loading} /> <span className="pl-2">Resetting</span>
                            </>
                        )}
                        {!loading && <>Reset</>}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default ResetPassword;
