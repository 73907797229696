import * as z from 'zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetFooter } from '../../common/sheet.tsx';
import AddChargeForm from './forms/invoiceAddChargeForm';
import LoadingSpinner from '../../common/loadingSpinner';
import useInvoiceApi from '../../../hooks/api/useInvoiceApi';

const InvoiceAddChargeView = ({ isDialogOpen, setIsDialogOpen, invoiceId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { createLineItemForInvoice } = useInvoiceApi();

    const dropDownSchema = z.object({
        label: z.string(),
        value: z.string(),
    });

    const invoiceChargeSchema = z.object({
        charge_type_id: dropDownSchema,
        amount: z.coerce.number().min(0, { message: 'Required' }),
        description: z.string().min(0, { message: 'Required' }),
        total_quantity: z.coerce.number().min(0, { message: 'Required' }),
    });

    const formProps = useForm({
        resolver: zodResolver(invoiceChargeSchema),
        defaultValues: {
            total_quantity: 0,
        },
    });

    const { handleSubmit } = formProps;

    const onSubmit = (values) => {
        setIsLoading(true);
        createLineItemForInvoice({
            amount: values.amount,
            quantity: values.total_quantity,
            charge_type_id: values.charge_type_id.value,
            invoice_id: invoiceId,
            description: values.description,
        }).then(() => {
            setIsLoading(false);
            window.location.reload();
        });
    };

    return (
        <Sheet open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <SheetContent position="right" size="lg">
                <SheetHeader className="pb-36 overflow-y-auto h-full">
                    <SheetTitle className="text-3xl">Add Charge to Invoice</SheetTitle>
                    <SheetDescription className="text-rails-dark-blue">Add line item to invoice: {invoiceId}</SheetDescription>
                    <AddChargeForm formProps={formProps} />
                </SheetHeader>
                <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                    <div>
                        <Button disabled={isLoading} onClick={handleSubmit((e) => onSubmit(e))} type="submit">
                            {isLoading && (
                                <>
                                    <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                                    Creating
                                </>
                            )}
                            {!isLoading && <>Create</>}
                        </Button>
                    </div>
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
};

export default InvoiceAddChargeView;
