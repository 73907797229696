import { useState, useEffect } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import Select from 'react-select';
import { debounce } from 'lodash';
import { XCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { Switch } from '../../../common/switch.tsx';
import { FormField, FormItem, FormControl, FormMessage } from '../../../common/form.tsx';
import { Input } from '../../../common/input.tsx';
import { Button } from '@/components/ui/button';
import { searchForProductInvoiceProductsSearchGet, getOneInvoiceProductsProductIdGet } from '../../../../client/services.gen.ts';
import { wrapperFunction } from '../../../../client/wrapperFunction.ts';

const ProductEditForm = ({ selectedProduct }) => {
    const { control, handleSubmit } = useFormContext();

    const [searchResults, setSearchResults] = useState({});
    const [inputValues, setInputValues] = useState({});
    const [skus, setSkus] = useState({});
    const [showKitComponents, setShowKitComponents] = useState(selectedProduct.is_kit);

    const {
        fields: productArray,
        remove: removeProduct,
        append: appendProduct,
    } = useFieldArray({
        control,
        name: 'kit_items',
    });

    const performSearch = debounce(async (query, idx) => {
        if (!query || query.length < 3) return;

        try {
            const response = await searchForProductInvoiceProductsSearchGet({ sku: query });
            const items = response?.items || [];
            setSearchResults((prevResults) => ({
                ...prevResults,
                [idx]: items,
            }));
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    }, 500); // Trigger search based on time delay

    const handleSearchInputChange = (newValue, idx) => {
        setInputValues((prevValues) => ({
            ...prevValues,
            [idx]: newValue,
        }));

        // Only trigger search if input length is 3 or more and divisible by 3
        if (newValue.length >= 3 && newValue.length % 3 === 0) {
            performSearch(newValue, idx);
        }
    };

    useEffect(() => {
        const fetchSkus = async () => {
            const skuPromises = selectedProduct.kit_items.map((item) =>
                wrapperFunction(getOneInvoiceProductsProductIdGet, { productId: item.product_id }).then((res) => ({
                    id: item.product_id,
                    sku: res.sku,
                })),
            );

            const skuResults = await Promise.all(skuPromises);
            const skuMap = skuResults.reduce((acc, { id, sku }) => ({ ...acc, [id]: sku }), {});
            setSkus(skuMap);
        };

        if (selectedProduct && selectedProduct.is_kit) {
            fetchSkus();
        }
    }, [selectedProduct]);

    const handleResultSelect = (selectedOption, field) => {
        field.onChange(selectedOption ? { label: selectedOption.label, value: selectedOption.value } : '');
    };

    const selectOptions = (idx) =>
        searchResults[idx]?.map((item) => ({
            label: item.sku,
            value: item.id,
        })) || [];

    return (
        <form onSubmit={handleSubmit}>
            <div className="space-y-8">
                <div className="pt-4">
                    <div className="flex text-xl font-medium">Charge Rate</div>
                    <FormField
                        control={control}
                        name="unit_charge"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input className="mt-2" placeholder="Enter charge rate" type="number" {...field} onChange={(e) => field.onChange(parseFloat(e.target.value))} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="pt-4">
                    <div className="flex text-xl font-medium">Excluded from Pricing</div>
                    <div className="mt-2 flex items-center space-x-4">
                        <FormField
                            control={control}
                            name="exclude_from_pick_rate"
                            render={({ field }) => (
                                <>
                                    <FormItem>
                                        <FormControl>
                                            <Switch onCheckedChange={field.onChange} checked={field.value} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                    <span>Is this a promotional item that should be excluded from pricing rules?</span>
                                </>
                            )}
                        />
                    </div>
                </div>
                <div className="pt-4">
                    <div className="flex text-xl font-medium">Count Kit as Single Unit for Invoicing</div>
                    <div className="mt-2 flex items-center space-x-4">
                        <FormField
                            control={control}
                            name="count_as_single_unit"
                            render={({ field }) => (
                                <>
                                    <FormItem>
                                        <FormControl>
                                            <Switch onCheckedChange={field.onChange} checked={field.value} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                    <span>For per-item charges, should the kit be counted as a single unit?</span>
                                </>
                            )}
                        />
                    </div>
                </div>
                <div className="pt-4">
                    <div className="flex text-xl font-medium">Is a Kit</div>
                    <div className="mt-2 flex items-center space-x-4">
                        <FormField
                            control={control}
                            name="is_kit"
                            render={({ field }) => (
                                <>
                                    <FormItem>
                                        <FormControl>
                                            <Switch
                                                onCheckedChange={(checked) => {
                                                    field.onChange(checked);
                                                    setShowKitComponents(!showKitComponents);
                                                }}
                                                checked={field.value}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                    <span>Does this SKU represent a kit?</span>
                                </>
                            )}
                        />
                    </div>
                </div>
                <div className="pt-4">
                    <div className="flex text-xl font-medium">Kit Components</div>
                    {showKitComponents &&
                        productArray.map((product, idx) => (
                            <div key={product.id} className="grid grid-cols-12 gap-2">
                                <div className="col-span-1 flex items-center justify-center">
                                    <XCircleIcon onClick={() => removeProduct(idx)} className="h-6 w-6 text-red-500 cursor-pointer" />
                                </div>
                                <div className="col-span-11">
                                    <div className="pt-2 grid grid-cols-2 gap-4">
                                        <FormField
                                            control={control}
                                            name={`kit_items.${idx}.product`}
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <Select
                                                            isSearchable
                                                            placeholder="Search by SKU"
                                                            onInputChange={(newValue) => handleSearchInputChange(newValue, idx)}
                                                            options={selectOptions(idx)}
                                                            onChange={(selectedOption) => handleResultSelect(selectedOption, field, idx)}
                                                            value={field.value || (skus[product.product_id] ? { label: skus[product.product_id], value: product.product_id } : '')}
                                                            inputValue={inputValues[idx] || ''}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={control}
                                            name={`kit_items.${idx}.quantity`}
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <Input
                                                            className="mt-2"
                                                            placeholder="Enter SKU quantity"
                                                            type="number"
                                                            {...field}
                                                            defaultValue={product.quantity || ''}
                                                            onChange={(e) => field.onChange(parseFloat(e.target.value))}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}

                    {showKitComponents && (
                        <div className="mt-4 text-gray-500">
                            <Button
                                type="button"
                                className="h-8"
                                variant="outline"
                                onClick={() => {
                                    appendProduct({
                                        product: '',
                                        quantity: 1,
                                    });
                                }}
                            >
                                <PlusCircleIcon className="h-4 w-4 mr-2" />
                                Add mapping
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </form>
    );
};

export default ProductEditForm;
