import { useState, useEffect, useMemo } from 'react';

import useTableauApi from '../../../hooks/api/useTableauApi';

const BillAnalysisDashboard = ({ tableauToken }) => {
    const url = `${import.meta.env.VITE_APP_TABLEAU_DASHBOARD_URL_RAW_DATA}?CompanyId=${tableauToken.company_id}`;

    return <tableau-viz id="tableau-viz" src={url} width="100%" height="900" toolbar="hidden" token={tableauToken.jwt} />;
};

const AllCarrierBills = () => {
    const { getAuthData } = useTableauApi();
    const [tableauToken, setTableauToken] = useState({});

    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://prod-useast-b.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js';
        script.type = 'module';

        document.body.appendChild(script);
        getAuthData().then((res) => {
            setTableauToken(res.data);
        });
    }, []);

    const memoizedDashboard = useMemo(() => <BillAnalysisDashboard tableauToken={tableauToken} />, [tableauToken]);

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">All Carrier Bills</h1>
                        </div>
                        {tableauToken && <div>{memoizedDashboard}</div>}
                    </div>
                </main>
            </div>
        </div>
    );
};

export default AllCarrierBills;
