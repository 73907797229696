import * as z from 'zod';
import { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetFooter } from '../../common/sheet.tsx';
import useInvoiceApi from '../../../hooks/api/useInvoiceApi.js';
import ConfigGenerationForm from './forms/configForm.jsx';
import { getDropdownValueObj } from '../../../consts/func.js';
import { invoiceConfigDateTypeOptions, invoiceConfigDeliveryMethodOptions } from '../../../consts/vars.js';
import { useToast } from '../../common/use-toast.tsx';

const ConfigDialog = ({ isDialogOpen, setIsDialogOpen, fetchConfigs, initialValues }) => {
    const [loading, setLoading] = useState(false);
    const [invoicecronString, setInvoiceCronString] = useState(initialValues?.invoice?.cadence);
    const [chargeInvoiceCronString, setChargeInvoiceCronString] = useState(initialValues?.chargetypes_invoicelevel?.cadence);
    const cronValidationRef = useRef(() => {});
    const { putInvoiceConfig, putInvoiceChargeConfig, putPackageChargeConfig, createInvoiceConfig, createInvoiceChargeConfig, createPackageChargeConfig } = useInvoiceApi();
    const { toast } = useToast();

    const configSchema = z.object({
        same_cadence_as_invoice: z.boolean(),
        date_ship_or_order: z.object({
            label: z.string(),
            value: z.string(),
        }),
        // use_label_data_if_carrier_missing: z.object({
        //     label: z.string(),
        //     value: z.boolean(),
        // }),
        // shipping_charge_label_or_carrier: z.object({
        //     label: z.string(),
        //     value: z.string(),
        // }),
        delivery_method: z.object({
            label: z.string(),
            value: z.string(),
        }),
    });

    const formProps = useForm({
        resolver: zodResolver(configSchema),
    });

    useEffect(() => {
        formProps.reset({
            ...initialValues,
            invoice_cadence: initialValues?.invoice?.cadence,
            delivery_method: getDropdownValueObj(initialValues?.invoice?.delivery_method, invoiceConfigDeliveryMethodOptions),

            same_cadence_as_invoice: false,
            charge_invoice_cadence: initialValues?.chargetypes_invoicelevel?.cadence,

            date_ship_or_order: getDropdownValueObj(initialValues?.chargetypes_packagelevel?.date_ship_or_order, invoiceConfigDateTypeOptions),
            // shipping_charge_label_or_carrier: getDropdownValueObj(initialValues?.chargetypes_packagelevel?.shipping_charge_label_or_carrier, invoiceConfigChargeTypeOptions),
            // use_label_data_if_carrier_missing: getDropdownValueObj(initialValues?.chargetypes_packagelevel?.use_label_data_if_carrier_missing, invoiceConfigShippingInvoiceOptions),
        });
    }, [initialValues]);

    const { handleSubmit, reset } = formProps;

    const onSubmit = (values) => {
        if (!invoicecronString) {
            toast({
                variant: 'destructive',
                title: 'Missing invoice billing period.',
            });
            return;
        }
        if (!chargeInvoiceCronString && !values.same_cadence_as_invoice) {
            toast({
                variant: 'destructive',
                title: 'Missing invoice level charges billing period.',
            });
            return;
        }
        setLoading(true);

        const invoiceData = {
            id: initialValues?.invoice?.id,
            default: true,
            cadence: invoicecronString || initialValues?.invoice?.cadence,
            delivery_method: values.delivery_method.value,
        };

        const invoiceChargeData = {
            id: initialValues?.chargetypes_invoicelevel?.id,
            default: true,
            cadence: values.same_cadence_as_invoice ? invoiceData.cadence : chargeInvoiceCronString || initialValues?.chargetypes_invoicelevel?.cadence,
        };

        const packageChargeData = {
            id: initialValues?.chargetypes_packagelevel?.id,
            default: true,
            date_ship_or_order: values.date_ship_or_order.value,
            shipping_charge_label_or_carrier: 'label',
            use_label_data_if_carrier_missing: true,
        };

        const runInvoiceConfig = () => (invoiceData.id ? putInvoiceConfig(invoiceData) : createInvoiceConfig(invoiceData));
        const runInvoiceChargeConfig = () => (invoiceChargeData.id ? putInvoiceChargeConfig(invoiceChargeData) : createInvoiceChargeConfig(invoiceChargeData));
        const runPackageChargeConfig = () => (packageChargeData.id ? putPackageChargeConfig(packageChargeData) : createPackageChargeConfig(packageChargeData));

        Promise.all([runInvoiceConfig(), runInvoiceChargeConfig(), runPackageChargeConfig()]).then(async (resp) => {
            if (resp) {
                reset();
                await fetchConfigs();
                setLoading(false);
                setIsDialogOpen(false);
            }
        });
    };

    return (
        <Sheet open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <SheetContent position="right" size="lg">
                <SheetHeader className="pb-36 overflow-y-auto h-full">
                    <SheetTitle className="text-3xl">Edit Invoicing Configurations</SheetTitle>
                    <ConfigGenerationForm
                        formProps={formProps}
                        setInvoiceCronString={setInvoiceCronString}
                        setChargeInvoiceCronString={setChargeInvoiceCronString}
                        cronValidationRef={cronValidationRef}
                    />
                </SheetHeader>
                <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                    <Button className={loading ? 'animate-pulse' : ''} onClick={handleSubmit(onSubmit)} type="submit">
                        {loading ? 'Submitting' : 'Submit'}
                    </Button>
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
};

export default ConfigDialog;
