import * as z from 'zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger, SheetFooter } from '../../common/sheet.tsx';
import PricingCategoryForm from './forms/pricingCategoryForm.jsx';
import useInvoiceApi from '../../../hooks/api/useInvoiceApi.js';

const PricingCategoryEdit = ({ pricingCategoryValues, setPricingCategory, chargeLevels }) => {
    const [loading, setLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { updateInvoiceChargeTypes } = useInvoiceApi();

    const pricingCategoryCreateFormDefaultValues = {
        name: pricingCategoryValues.name,
        charge_on_invoice: pricingCategoryValues.charge_on_invoice,
        description: pricingCategoryValues.description,
        required: pricingCategoryValues.required,
        charge_level: chargeLevels.filter((charge) => charge.value === pricingCategoryValues.charge_level)[0],
    };

    const dropDownSchema = z.object({
        label: z.string(),
        value: z.string(),
    });

    const pricingCategorySchema = z.object({
        name: z.string().trim().min(3, { message: 'Required' }),
        charge_on_invoice: z.string().trim().min(3, { message: 'Required' }),
        default: z.boolean().optional(),
        required: z.boolean(),
        description: z.string().trim().min(3, { message: 'Required' }),
        charge_level: dropDownSchema,
    });

    const formProps = useForm({
        resolver: zodResolver(pricingCategorySchema),
        defaultValues: pricingCategoryCreateFormDefaultValues,
    });

    const { handleSubmit, reset } = formProps;

    const onSubmit = (values) => {
        setLoading(true);
        updateInvoiceChargeTypes(values, pricingCategoryValues.id).then(async (resp) => {
            if (resp) {
                window.location.reload();
            }
        });
    };

    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button
                    onClick={() => {
                        setIsDialogOpen(true);
                    }}
                    className="ml-4"
                >
                    Edit
                </Button>
            </SheetTrigger>
            {isDialogOpen && (
                <SheetContent position="right" size="lg">
                    <SheetHeader className="pb-36 overflow-y-auto h-full">
                        <SheetTitle className="text-3xl">Edit Pricing Category</SheetTitle>
                        <SheetDescription className="text-rails-dark-blue">
                            <PricingCategoryForm formProps={formProps} chargeLevels={chargeLevels} />
                        </SheetDescription>
                    </SheetHeader>
                    <SheetFooter
                        className={`${
                            loading ? 'animate-pulse disabled' : ' '
                        } border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8`}
                    >
                        <div>
                            <Button onClick={handleSubmit((e) => onSubmit(e))} type="submit">
                                {loading ? 'Saving' : 'Save'}
                            </Button>
                        </div>
                    </SheetFooter>
                </SheetContent>
            )}
        </Sheet>
    );
};

export default PricingCategoryEdit;
