import * as z from 'zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger, SheetFooter } from '../../common/sheet.tsx';
import ReceivingFeeForm from './forms/receivingFeesForm';
import LoadingSpinner from '../../common/loadingSpinner';
import useReceivingApi from '../../../hooks/api/useReceivingApi';

const ReceivingFeesCreate = ({ customerData }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { createReceivingFee } = useReceivingApi();

    const ReceivingFeeCreateFormDefaultValues = {
        name: '',
        all_customers: false,
        customer_ids: [],
        charge_rate_type: null,
        charge_amount: 0,
    };

    const dropDownSchema = z.object({
        label: z.string(),
        value: z.string(),
    });

    const ReceivingFeeSchema = z
        .object({
            name: z.string().trim().min(3, { message: 'Required' }),
            all_customers: z.boolean(),
            customer_ids: z.array(dropDownSchema).optional(),
            charge_rate_type: dropDownSchema,
            charge_amount: z.coerce.number().nullish(),
        })
        .refine(
            (input) => {
                if (!input.all_customers && input.customer_ids.length === 0) {
                    return false;
                }

                return true;
            },
            {
                message: 'Customer selection is required if this fee does not apply to all customers.',
                path: ['customer_ids'],
            },
        );
    const formProps = useForm({
        resolver: zodResolver(ReceivingFeeSchema),
        defaultValues: ReceivingFeeCreateFormDefaultValues,
    });

    const { handleSubmit, reset } = formProps;

    const onSubmit = (values) => {
        setIsLoading(true);
        createReceivingFee({
            name: values.name,
            all_customers: values.all_customers,
            customer_ids: values.customer_ids.map((customer) => customer.value),
            charge_type: values.charge_rate_type.value,
            charge_amount: values.charge_amount,
            active: true,
        }).then((resp) => {
            if (resp.success) {
                setIsLoading(false);
                setIsDialogOpen(false);
                window.location.reload();
            }
        });
    };

    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button
                    onClick={() => {
                        reset();
                        setIsDialogOpen(true);
                    }}
                    size="sm"
                    className="ml-4 h-8 text-sm"
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Create Receiving Fee
                </Button>
            </SheetTrigger>
            {isDialogOpen && (
                <SheetContent position="right" size="lg">
                    <SheetHeader className="pb-36 overflow-y-auto h-full">
                        <SheetTitle className="text-3xl">Create New Receiving Fee</SheetTitle>
                        <SheetDescription className="text-rails-dark-blue">
                            <ReceivingFeeForm formProps={formProps} customerData={customerData} />
                        </SheetDescription>
                    </SheetHeader>
                    <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                        <div>
                            <Button disabled={isLoading} onClick={handleSubmit((e) => onSubmit(e))} type="submit">
                                {isLoading && (
                                    <>
                                        <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                                        Creating
                                    </>
                                )}
                                {!isLoading && <>Create</>}
                            </Button>
                        </div>
                    </SheetFooter>
                </SheetContent>
            )}
        </Sheet>
    );
};

export default ReceivingFeesCreate;
