import useFetch from '../useFetch';
import { backendUri } from '../../consts/vars';
import { getRequestHeader } from '../../utils/auth';

const useReceivingApi = () => {
    const { makeApiCall } = useFetch();

    const getReceivingFee = () => {
        const request = {
            method: 'get',
            url: `${backendUri}/receiving_orders/charges/`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const createReceivingFee = (body) => {
        const request = {
            method: 'post',
            url: `${backendUri}/receiving_orders/charges/`,
            headers: getRequestHeader(),
            data: body,
        };
        return makeApiCall(request);
    };

    const updateReceivingFee = (body) => {
        const request = {
            method: 'put',
            url: `${backendUri}/receiving_orders/charges/${body.id}`,
            headers: getRequestHeader(),
            data: body,
        };
        return makeApiCall(request);
    };

    const deleteReceivingFee = (stoargeFeeId) => {
        const request = {
            method: 'delete',
            url: `${backendUri}/receiving_orders/charges/${stoargeFeeId}`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    return {
        getReceivingFee,
        createReceivingFee,
        updateReceivingFee,
        deleteReceivingFee,
    };
};

export default useReceivingApi;
