import Select from 'react-select';

import { Form, FormField, FormItem, FormControl, FormMessage } from '../../../common/form.tsx';

const InvoiceCreateFrom = ({ formProps, customerData }) => (
    <Form {...formProps}>
        <form className="space-y-8">
            {!formProps.getValues('all_customers') && (
                <div className="pt-4">
                    <div className="flex text-xl font-medium">Customer</div>
                    <div className="mt-2">
                        <FormField
                            control={formProps.control}
                            name="customer"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Select
                                            {...field}
                                            options={customerData}
                                            placeholder="Customer Name"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DBF3D8',
                                                    primary: '#92BAA3',
                                                },
                                            })}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
            )}
        </form>
    </Form>
);

export default InvoiceCreateFrom;
