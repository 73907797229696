/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import { NumericFormat } from 'react-number-format';
import { useParams } from 'react-router-dom';
import { DataTable } from '../../common/datatable/dataTable.tsx';
import { DataTableColumnHeader } from '../../common/datatable/dataTableColumnHeader.tsx';
import useGeneralApi from '../../../hooks/api/useGeneralApi';
import InvoiceEditChargeView from './invoiceEditChargeView';
import InvoiceEditLineChargeView from './invoiceEditLineChargeView';
import InvoiceAddChargeView from './invoiceAddChargeView';
import { Button } from '@/components/ui/button';
import { getInvoiceDetailInvoiceInvoiceDetailInvoiceIdGet } from '../../../client/services.gen.ts';
import { wrapperFunction } from '../../../client/wrapperFunction.ts';

const SingleInvoiceDetail = () => {
    const { invoiceId } = useParams();
    const { getViewData } = useGeneralApi();
    const [isLoading, setIsLoading] = useState(false);
    const [nonInvoiceLevelData, setNonInvoiceLevelData] = useState([]);
    const [invoiceLevelCharges, setInvoiceLevelCharges] = useState([]);
    const [selectedCharge, setSelectedCharge] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState([]);
    const [isOpen, setIsOpen] = useState(false); // Use isOpen consistently
    const [openAddCharge, setAddCharge] = useState(false);
    const [isLineChargeOpen, setIsLineChargeOpen] = useState(false);

    const openEditForm = (row) => {
        const selectedRuleArr = nonInvoiceLevelData
            .map((data) => data.charges)
            .flat()
            .filter((charge) => charge.line_item_id === row.original.line_item_id);
        setSelectedCharge({ ...selectedRuleArr[0] }); // Create a new object
        setIsOpen(true);
    };

    const openEditInvoiceLevelChargeForm = (row) => {
        const selectedRuleArr = invoiceLevelCharges.filter((charge) => charge.line_item_id === row.original.line_item_id);
        setSelectedCharge({ ...selectedRuleArr[0] });
        setIsOpen(true);
    };

    const openEditLineForm = (row) => {
        const selectedRuleArr = nonInvoiceLevelData.filter((data) => data.order_id === row.order_id);
        setSelectedOrder(selectedRuleArr[0]);
        setIsLineChargeOpen(true);
    };

    const invoiceColumns = [
        {
            accessorKey: 'order_number',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Order Number" />,
            cell: ({ row }) => <div className="w-[200px] font-medium">{row.getValue('order_number')}</div>,
            enableSorting: true,
            enableHiding: false,
        },
    ];

    const invoiceLevelChargeColumns = [
        {
            accessorKey: 'description',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Charge" />,
            cell: ({ row }) => {
                // Use row.original to access the complete row data
                const { description } = row.original;
                const chargeTypeName = row.original.charge_type_name;
                const displayText = description === null ? chargeTypeName : description;

                return (
                    <div className="w-[200px] font-medium hover:underline" onClick={() => openEditInvoiceLevelChargeForm(row)}>
                        <a href="#">{displayText}</a>
                    </div>
                );
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'quantity',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Quantity" />,
            cell: ({ row }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">
                        <NumericFormat value={row.getValue('quantity')} displayType="text" thousandSeparator="," decimalScale={0} fixedDecimalScale />
                    </span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'amount',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Charge" />,
            cell: ({ row }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">
                        <NumericFormat value={row.getValue('amount')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
                    </span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
    ];

    const chargeColumns = [
        {
            accessorKey: 'description',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Charge Name" />,
            cell: ({ row }) => (
                <div className="w-[200px] font-medium hover:underline" onClick={() => openEditForm(row)}>
                    <a href="#">{row.getValue('description')}</a>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'quantity',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Quantity" />,
            cell: ({ row }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">
                        <NumericFormat value={row.getValue('quantity')} displayType="text" thousandSeparator="," decimalScale={0} fixedDecimalScale />
                    </span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'amount',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Total Invoice Amount" />,
            cell: ({ row }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">
                        <NumericFormat value={row.getValue('amount')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
                    </span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
    ];

    useEffect(() => {
        setIsLoading(true);
        const fetchInvoiceDetails = async () => {
            try {
                const output = await wrapperFunction(getInvoiceDetailInvoiceInvoiceDetailInvoiceIdGet, { invoiceId });
                setNonInvoiceLevelData(output.package_charges);

                const normalizeCharge = (charge, type) => ({
                    ...charge,
                    description: type === 'product' ? charge.name : charge.description,
                    charge_type_name: type,
                });

                const combinedCharges = [
                    ...(output.product_charges || []).map((charge) => normalizeCharge(charge, 'product')),
                    ...(output.manual_charges || []).map((charge) => normalizeCharge(charge, 'manual')),
                    ...(output.storage_charges || []).map((charge) => normalizeCharge(charge, 'storage')),
                ];
                setInvoiceLevelCharges(combinedCharges);
            } catch (error) {
                // Handle error here
                console.error('Error fetching invoice details:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchInvoiceDetails();
    }, [invoiceId]);

    const DataTableComponent = () => (
        <DataTable
            loading={isLoading}
            data={nonInvoiceLevelData}
            columns={invoiceColumns}
            collapsible
            showActionButton={false}
            showActionButtonInToolbar
            showActionButtonInCollapsible
            collapsibleContent={({ row }) => {
                if (row?.charges) {
                    return (
                        <DataTable
                            loading={isLoading}
                            data={row.charges}
                            columns={chargeColumns}
                            isMultiSelectRows={false}
                            showPagination={false}
                            showActionButtonInToolbar
                            showActionButtonInCollapsible
                            collapsible={false}
                            ActionButton={
                                <Button className="p-4" onClick={() => openEditLineForm(row)}>
                                    <Plus className="h-4 w-4 mr-2" />
                                    Add Charge
                                </Button>
                            }
                        />
                    );
                }
                return null;
            }}
            isMultiSelectRows={false}
            tableName="invoices"
        />
    );

    const DataTableComponentInvoiceLevelCharges = () => (
        <DataTable
            loading={isLoading}
            data={invoiceLevelCharges}
            columns={invoiceLevelChargeColumns}
            showActionButton
            ActionButton={
                <Button
                    className="p-4"
                    onClick={() => {
                        setAddCharge(true);
                    }}
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Charge
                </Button>
            }
            showActionButtonInToolbar
            showActionButtonInCollapsible
            isMultiSelectRows={false}
            tableName="invoices"
        />
    );

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">Invoice #{invoiceId}</h1>
                            <h2 className="text-xs text-rails-dark-blue">Review and edit line items on this page.</h2>
                        </div>

                        <div className="mt-12 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="text-lg font-semibold text-rails-dark-blue">Invoice Level Charges</div>
                            <div className="pt-2">
                                <div>{invoiceLevelCharges && <DataTableComponentInvoiceLevelCharges />}</div>
                            </div>
                            <div className="pt-12 text-lg font-semibold text-rails-dark-blue">Order and Package Charges</div>
                            <div className="pt-2">
                                <div>{nonInvoiceLevelData && <DataTableComponent />}</div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            {isOpen && <InvoiceEditChargeView isOpen={isOpen} setIsOpen={setIsOpen} invoiceId={invoiceId} lineItemId={selectedCharge?.line_item_id} invoiceData={selectedCharge} />}
            {isLineChargeOpen && <InvoiceEditLineChargeView isOpen={isLineChargeOpen} setIsOpen={setIsLineChargeOpen} invoiceId={invoiceId} orderData={selectedOrder} />}
            {openAddCharge && <InvoiceAddChargeView isDialogOpen={openAddCharge} setIsDialogOpen={setAddCharge} invoiceId={invoiceId} />}
        </div>
    );
};

export default SingleInvoiceDetail;
