import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';
import { ArrowDownTrayIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetFooter } from '../../common/sheet.tsx';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../common/dropdown-menu.tsx';
import { DataTable } from '../../common/datatable/dataTable.tsx';
import { DataTableColumnHeader } from '../../common/datatable/dataTableColumnHeader.tsx';
import { Button } from '@/components/ui/button';
import useInvoiceApi from '../../../hooks/api/useInvoiceApi';
import InvoiceAddChargeView from './invoiceAddChargeView';
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../../common/alert-dialog.tsx';

const invoiceColumns = [
    {
        accessorKey: 'charge_name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Charge in Rails" />,
        cell: ({ row }) => <div className="w-[100px] font-medium">{row.getValue('charge_name')}</div>,
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: 'quickbooks_mapping',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Charge in ERP" />,
        cell: ({ row }) => <div className="w-[100px] font-medium">{row.getValue('quickbooks_mapping')}</div>,
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: 'quantity',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Number of Charges" />,
        cell: ({ row }) => (
            <div className="flex flex-wrap space-x-2">
                <NumericFormat value={row.getValue('quantity')} displayType="text" thousandSeparator="," />
            </div>
        ),
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: 'amount',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Amount" />,
        cell: ({ row }) => (
            <div className="flex flex-wrap space-x-2">
                <span className="max-w-[500px] text-wrap">
                    <NumericFormat value={row.getValue('amount')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
                </span>
            </div>
        ),
        enableSorting: false,
        enableHiding: false,
    },
];

const InvoiceDetailView = ({ open, setOpen, invoiceData }) => {
    const { getInvoiceDetailFile, uploadToQuickBooks } = useInvoiceApi();
    const [loading, setLoading] = useState(false);
    const [loadingQuickBooks, setLoadingQuickBooks] = useState(false);
    const [successUpload, setSuccessUpload] = useState(false);
    const [openAddCharge, setAddCharge] = useState(false);
    const navigate = useNavigate();

    const handleDownload = (invoiceId) => {
        setLoading(true);
        getInvoiceDetailFile(invoiceId).then((res) => {
            const tempLink = document.createElement('a');
            tempLink.href = res.data.file_url;
            tempLink.setAttribute('download', `shippy_${invoiceId}_invoice_report.csv`);
            tempLink.click();
            setLoading(false);
        });
    };

    const handleUploadToQuickBooks = (invoiceId) => {
        setLoadingQuickBooks(true);
        getInvoiceDetailFile(invoiceId).then((res) => {
            if (res.success) {
                uploadToQuickBooks(invoiceId, res.data.file_url).then((resQb) => {
                    if (resQb.success) {
                        setSuccessUpload(true);
                        setLoadingQuickBooks(false);
                    }
                });
            }
            setLoading(false);
        });
    };

    const chargeDetails =
        invoiceData !== null && invoiceData.charges !== null ? invoiceData.charges.filter((charge) => charge.amount !== 0 && !(charge.amount === '0.000000')) : [];

    let canAddCharge = false;
    if (invoiceData?.status !== 'closed') {
        canAddCharge = true;
    }

    const NavButton = canAddCharge ? (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button className="p-4">
                    <Plus className="h-4 w-4 mr-2" />
                    Manage Charge
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <DropdownMenuItem
                    onClick={() => {
                        setAddCharge(true);
                    }}
                >
                    Create Charge
                </DropdownMenuItem>
                <DropdownMenuItem
                    onClick={() => {
                        navigate(`/invoices/${invoiceData.invoice_id}`);
                    }}
                >
                    Update Charge
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    ) : null;

    return (
        invoiceData && (
            <div>
                <Sheet open={open} onOpenChange={setOpen}>
                    <SheetContent position="right" size="lg">
                        <SheetHeader className="pb-36 overflow-y-auto h-full">
                            <SheetTitle className="text-3xl">{invoiceData && invoiceData.customer_name}</SheetTitle>
                            <SheetDescription>{`Invoice ID: ${invoiceData.invoice_id}`}</SheetDescription>

                            <div className="pt-4">
                                <h2 className="text-md font-bold text-rails-dark-blue">Charge Details</h2>
                                <div>
                                    <div className="container mx-auto py-2">
                                        {chargeDetails && (
                                            <DataTable data={chargeDetails} columns={invoiceColumns} isMultiSelectRows={false} ActionButton={NavButton} showActionButtonInToolbar />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </SheetHeader>
                        <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                            <div className="grid grid-cols-2 space-x-4">
                                <Button className={loading ? 'animate-pulse' : ''} onClick={() => handleDownload(invoiceData.invoice_id)} type="button">
                                    <ArrowDownTrayIcon className="h-4 w-4 mr-2" />
                                    {loading ? 'Downloading' : 'Download'}
                                </Button>
                                <Button className={loadingQuickBooks ? 'animate-pulse' : ''} onClick={() => handleUploadToQuickBooks(invoiceData.invoice_id)} type="button">
                                    <PaperAirplaneIcon className="h-4 w-4 mr-2" />
                                    {loadingQuickBooks ? 'Exporting' : 'Export'}
                                </Button>
                            </div>
                        </SheetFooter>
                    </SheetContent>
                </Sheet>

                {openAddCharge && <InvoiceAddChargeView isDialogOpen={openAddCharge} setIsDialogOpen={setAddCharge} invoiceId={invoiceData.invoice_id} />}

                {successUpload && (
                    <AlertDialog open={successUpload}>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>Invoice Upload to QuickBooks Successfully!</AlertDialogTitle>
                                <AlertDialogDescription>Your invoice has been uploaded successfully. Click Done to continue</AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel onClick={() => setSuccessUpload(false)}>Done</AlertDialogCancel>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                )}
            </div>
        )
    );
};

export default InvoiceDetailView;
