import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetFooter, SheetTitle } from '../../common/sheet.tsx';
import { Input } from '../../common/input.tsx';
import { Switch } from '../../common/switch.tsx';
import { ScrollArea } from '../../common/scroll-area.tsx';

import ProductEdit from './productEdit';
import KitComponentTable from './kitComponentsTable';

export const showUnits = (data) => {
    if (data) return data.toLowerCase();
    return '-';
};

const ProductView = ({ open, setOpen, selectedProduct }) =>
    selectedProduct && (
        <Sheet open={open} onOpenChange={setOpen}>
            <SheetContent position="right" size="lg">
                <SheetHeader className="pb-36 overflow-y-auto h-full">
                    <SheetTitle className="text-3xl">{selectedProduct && `${selectedProduct.name}`}</SheetTitle>
                    <SheetDescription>
                        <div>WMS Product ID: {selectedProduct.wms_product_id}</div>
                    </SheetDescription>
                    <div className="pt-4">
                        <span className="flex text-xl font-medium">SKU</span>
                        <Input className="bg-white w-72 mt-2" type="text" placeholder={selectedProduct.sku} disabled />
                    </div>
                    <div className="pt-4">
                        <span className="flex text-xl font-medium">Customer</span>
                        <Input className="bg-white w-72 mt-2" type="text" placeholder={selectedProduct.customer?.name} disabled />
                    </div>
                    <div className="pt-4">
                        <span className="flex text-xl font-medium">Dimension</span>
                        <Input
                            className="bg-white w-72 mt-2"
                            type="text"
                            placeholder={
                                selectedProduct.dimensions &&
                                ` L: ${selectedProduct.dimensions?.length ?? '-'} ${showUnits(selectedProduct.dimensions?.length_unit)}  H: ${
                                    selectedProduct.dimensions?.height ?? '-'
                                } ${showUnits(selectedProduct.dimensions?.length_unit)}  W: ${selectedProduct.dimensions?.width ?? '-'} ${showUnits(
                                    selectedProduct.dimensions?.length_unit,
                                )} Wt: ${selectedProduct.dimensions?.weight ?? '-'} ${showUnits(selectedProduct.dimensions?.weight_unit)}
                                `
                            }
                            disabled
                        />
                    </div>
                    <div className="pt-4">
                        <span className="flex text-xl font-medium">Charge Rate</span>
                        <Input className="bg-white w-72 mt-2" type="text" placeholder={selectedProduct.unit_charge && `$${selectedProduct.unit_charge}`} disabled />
                    </div>
                    <div className="pt-4">
                        <span className="flex text-xl font-medium">Exclude from Pricing</span>
                        <div className="mt-2 flex items-center space-x-4">
                            <Switch checked={selectedProduct.exclude_from_pick_rate} disabled />
                        </div>
                    </div>
                    <div className="pt-4">
                        <span className="flex text-xl font-medium">Count Kit as Single Unit for Invoicing</span>
                        <div className="mt-2 flex items-center space-x-4">
                            <Switch checked={selectedProduct.count_as_single_unit} disabled />
                        </div>
                    </div>
                    {selectedProduct.is_kit && (
                        <div className="pt-4">
                            <ScrollArea className="overflow-y-auto overflow-x-auto max-h-72">
                                <div className="flex text-xl font-medium pb-2">Kit Components</div>
                                <KitComponentTable selectedProduct={selectedProduct} />
                            </ScrollArea>
                        </div>
                    )}
                </SheetHeader>
                <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                    <div>
                        <ProductEdit selectedProduct={selectedProduct} />
                    </div>
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );

export default ProductView;
