import React, { useState, useEffect } from 'react';
import { DataTable } from '@/components/ui/datatable/data-table';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogTrigger, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { Plus, XCircleIcon } from 'lucide-react';
import { 
    getQbDataQbQbUiFormOptionsGet,
    getInvoiceChargeTypesGet,   
    getQbAccountsGet,
    getQbNetTermsGet,
    getQbPaymentTermsGet,
    getInvoiceCustomersGet,
    getQbReportFormatGet,
    getQbCustomerMappingsGet,
    getQbServiceCategoryGet,
    getTokenQbGetTokenGet,
    putQbNetTermsPut,
    putQbPaymentTermsPut,
    putQbReportFormatPut,
    bulkDeleteQbServiceCategoryBulkDelete,
    bulkDeleteQbCustomerMappingsBulkDelete,
    postQbCustomerMappingsPost,
    postQbServiceCategoryPost
} from '@/client/services.gen';
import { wrapperFunction } from '@/client/wrapperFunction';

import {type Customer, type QBNetTerms, type QBPaymentTerms, type QBReportFormat, type QBCustomerMappings, type QBServiceCategory, type QBAccount, type QBToken, type GetQbDataQbQbUiFormOptionsGetResponse, type GetInvoiceChargeTypesGetResponse } from '@/client/types.gen';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import Select from 'react-select';

const AccountingSystems: React.FC = () => {
    const [netTerms, setNetTerms] = useState<QBNetTerms[]>([]);
    const [paymentTerms, setPaymentTerms] = useState<QBPaymentTerms[]>([]);
    const [reportFormats, setReportFormats] = useState<QBReportFormat[]>([]);
    const [customerMappings, setCustomerMappings] = useState<QBCustomerMappings[]>([]);
    const [serviceCategories, setServiceCategories] = useState<QBServiceCategory[]>([]);
    const [qbAccounts, setQbAccounts] = useState<QBAccount[]>([]);
    const [qbData, setQbData] = useState<GetQbDataQbQbUiFormOptionsGetResponse>({} as GetQbDataQbQbUiFormOptionsGetResponse);
    const [chargeTypes, setChargeTypes] = useState<GetInvoiceChargeTypesGetResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isNetTermDialogOpen, setIsNetTermDialogOpen] = useState(false);
    const [isPaymentTermDialogOpen, setIsPaymentTermDialogOpen] = useState(false);
    const [isReportFormatDialogOpen, setIsReportFormatDialogOpen] = useState(false);
    const [isAccountingSystemDialogOpen, setIsAccountingSystemDialogOpen] = useState(false);
    const [isQuickBooksSuccessDialogOpen, setIsQuickBooksSuccessDialogOpen] = useState(false);
    const [isCustomerMappingDialogOpen, setIsCustomerMappingDialogOpen] = useState(false);
    const [isServiceCategoryDialogOpen, setIsServiceCategoryDialogOpen] = useState(false);
    const [customers, setCustomers] = useState<Customer[]>([]);

    const getCustomers = async () => {
        try {
            // @ts-ignore
            const res = await wrapperFunction(getInvoiceCustomersGet, {});
            setCustomers(res);
        } catch (error) {
            console.error('Error loading Customer data:', error);
        }
    };

    const getQbAccounts = async () => {
        try {
            // @ts-ignore
            const res = await wrapperFunction(getQbAccountsGet, {});
            setQbAccounts(res);
        } catch (error) {
            console.error('Error loading QuickBooks Accounts data:', error);
        }
    };

    const getNetTerms = async () => {
        try {
            // @ts-ignore
            const res = await wrapperFunction(getQbNetTermsGet, {});
            setNetTerms(res);
        } catch (error) {
            console.error('Error loading Net Terms data:', error);
        }
    };

    const getPaymentTerms = async () => {
        try {
            // @ts-ignore
            const res = await wrapperFunction(getQbPaymentTermsGet, {});
            setPaymentTerms(res);
        } catch (error) {
            console.error('Error loading Payment Terms data:', error);
        }
    };

    const getReportFormats = async () => {
        try {
            // @ts-ignore
            const res = await wrapperFunction(getQbReportFormatGet, {});
            setReportFormats(res);
        } catch (error) {
            console.error('Error loading Report Formats data:', error);
        }
    };

    const getCustomerMappings = async () => {
        try {
            // @ts-ignore
            const res = await wrapperFunction(getQbCustomerMappingsGet, {});
            setCustomerMappings(res);
        } catch (error) {
            console.error('Error loading Customer Mappings data:', error);
        }
    };

    const getServiceCategories = async () => {
        try {
            // @ts-ignore
            const res = await wrapperFunction(getQbServiceCategoryGet, {});
            setServiceCategories(res);
        } catch (error) {
            console.error('Error loading Service Categories data:', error);
        }
    };

    const getQbData = async () => {
        try {
            // @ts-ignore
            const res = await wrapperFunction(getQbDataQbQbUiFormOptionsGet, {});
            setQbData(res);
        } catch (error) {
            console.error('Error loading QuickBooks Data:', error);
        }
    };

    const getChargeTypes = async () => {
        try {
            // @ts-ignore
            const res = await wrapperFunction(getInvoiceChargeTypesGet, {});
            setChargeTypes(res);
        } catch (error) {
            console.error('Error loading Charge Types data:', error);
        }
    };

    useEffect(() => {
        setLoading(true);
        Promise.all([
            getQbAccounts(),
            getQbData(),
            getCustomers(),
            getNetTerms(),
            getPaymentTerms(),
            getReportFormats(),
            getCustomerMappings(),
            getServiceCategories(),
            getChargeTypes()
        ]).catch(error => {
            console.error('Error fetching data:', error);
        });
        setLoading(false);
    }, []);

    const groupNetTermsByName = () => {
        return netTerms.reduce((acc, netTerm) => {
            const customer = customers.find(c => c.id === netTerm.customer_id);
            const customerName = customer ? customer.name : 'Unknown';
            
            if (!acc[netTerm.name]) {
                acc[netTerm.name] = {
                    netTermName: netTerm.name,
                    customers: [customerName],
                };
            } else {
                if (!acc[netTerm.name].customers.includes(customerName)) {
                    acc[netTerm.name].customers.push(customerName);
                }
            }
            return acc;
        }, {} as Record<string, { netTermName: string; customers: string[]; }>);
    };

    const groupPaymentTerms = () => {
        return paymentTerms.reduce((acc, paymentTerm) => {
            const key = `${paymentTerm.allow_ach}-${paymentTerm.allow_credit_card}-${paymentTerm.custom_credit_card_processing_fee}`;
            const customer = customers.find(c => c.id === paymentTerm.customer_id);
            const customerName = customer ? customer.name : 'Unknown';
            
            if (!acc[key]) {
                acc[key] = {
                    allow_ach: paymentTerm.allow_ach,
                    allow_credit_card: paymentTerm.allow_credit_card,
                    custom_credit_card_processing_fee: paymentTerm.custom_credit_card_processing_fee,
                    customers: [customerName],
                };
            } else {
                if (!acc[key].customers.includes(customerName)) {
                    acc[key].customers.push(customerName);
                }
            }
            return acc;
        }, {} as Record<string, { allow_ach: boolean; allow_credit_card: boolean; custom_credit_card_processing_fee: string; customers: string[]; }>);
    };

    const groupReportFormats = () => {
        return reportFormats.reduce((acc, reportFormat) => {
            const key = `${reportFormat.report_zero_charges}-${reportFormat.report_kit_as_components}-${reportFormat.email_to_cc_bill}-${reportFormat.customer_memo}`;
            const customer = customers.find(c => c.id === reportFormat.customer_id);
            const customerName = customer ? customer.name : 'Unknown';
            
            if (!acc[key]) {
                acc[key] = {
                    report_zero_charges: reportFormat.report_zero_charges,
                    report_kit_as_components: reportFormat.report_kit_as_components,
                    email_to_cc_bill: reportFormat.email_to_cc_bill || '',
                    customer_memo: reportFormat.customer_memo || '',
                    customers: [customerName],
                };
            } else {
                if (!acc[key].customers.includes(customerName)) {
                    acc[key].customers.push(customerName);
                }
            }
            return acc;
        }, {} as Record<string, { report_zero_charges: boolean; report_kit_as_components: boolean; email_to_cc_bill: string; customer_memo: string; customers: string[]; }>);
    };
    
    const netTermColumns = [
        {
            header: "Customers",
            accessorKey: 'customers',
            cell: ({ row }: { row: { original: { customers: string[] } } }) => (
                <div className="w-[200px]">
                    {row.original.customers.join(', ')}
                </div>
            ),
        },
        {
            header: "Net Term",
            accessorKey: 'netTermName',
        },
    ];

    const paymentTermColumns = [
        {
            header: "Customers",
            accessorKey: 'customers',
            cell: ({ row }: { row: { original: { customers: string[] } } }) => (
                <div className="w-[200px]">
                    {row.original.customers.join(', ')}
                </div>
            ),
        },
        {
            header: "Allow ACH",
            accessorKey: 'allow_ach',
        },
        {
            header: "Allow Credit Card",
            accessorKey: 'allow_credit_card',
        },
        {
            header: "Custom Credit Card Processing Fee",
            accessorKey: 'custom_credit_card_processing_fee',
            cell: ({ row }: { row: { original: { custom_credit_card_processing_fee: string } } }) => (
                <div>
                    {row.original.custom_credit_card_processing_fee === '0E-10' ? '0%' : `${(Math.ceil(parseFloat(row.original.custom_credit_card_processing_fee) * 1000) / 1000).toFixed(3)}%`}
                </div>
            ),
        },
    ];

    const reportFormatColumns = [
        {
            header: "Customers",
            accessorKey: 'customers',
            cell: ({ row }: { row: { original: { customers: string[] } } }) => (
                <div className="w-[200px]">
                    {row.original.customers.join(', ')}
                </div>
            ),
        },
        {
            header: "Report Zero Charges",
            accessorKey: 'report_zero_charges',
        },
        {
            header: "Report Kit As Components",
            accessorKey: 'report_kit_as_components',
        },
        {
            header: "Email To CC Bill",
            accessorKey: 'email_to_cc_bill',
            cell: ({ row }: { row: { original: { email_to_cc_bill: string | null } } }) => (
                <div>{row.original.email_to_cc_bill || '-'}</div>
            ),
        },
        {
            header: "Customer Memo",
            accessorKey: 'customer_memo',
            cell: ({ row }: { row: { original: { customer_memo: string | null } } }) => (
                <div>{row.original.customer_memo || '-'}</div>
            ),
        },
    ];

    const customerMappingColumns = [
        {
            header: "Rails Profile",
            accessorKey: 'customer',
            cell: ({ row }: { row: { original: { customer_id: string } } }) => {
                const customer = customers.find(c => c.id === row.original.customer_id);
                return <div>{customer ? customer.name : 'Unknown'}</div>;
            },
        },
        {
            header: "QuickBooks Customer",
            accessorKey: 'name',
        },
    ];

    const serviceCategoryColumns = [
        {
            header: "Rails Charge",
            accessorKey: 'invoice_category_mapping_name',
        },
        {
            header: "QuickBooks Service Category",
            accessorKey: 'name',
        },
    ];
    
    const groupedNetTermsData = Object.values(groupNetTermsByName());
    const paymentTermGroupedData = Object.values(groupPaymentTerms());
    const reportFormatGroupedData = Object.values(groupReportFormats());

    const netTermsSchema = z.object({
        name: z.string().min(1, "Net term name is required"),
        customer_ids: z.array(z.string()).min(1, "At least one customer must be selected"),
        source_id: z.string().min(1, "Net term ID is required"),
    });

    const netTermsForm = useForm<z.infer<typeof netTermsSchema>>({
        resolver: zodResolver(netTermsSchema),
        defaultValues: {
            name: '',
            customer_ids: [],
            source_id: '',
        },
    });

    const onSubmitNetTerms = async (values: z.infer<typeof netTermsSchema>) => {
        try {
            const payload = values.customer_ids.map(customerId => ({
                customer_id: customerId,
                accounting_system_id: qbAccounts[0]?.id,
                name: values.name,
                source_id: values.source_id,
                default_selection: true
            }));

            // @ts-ignore
            await wrapperFunction(putQbNetTermsPut, { requestBody: payload });
            setIsNetTermDialogOpen(false);

            getNetTerms(); // Refresh the net terms
        } catch (error) {
            console.error('Error submitting net term:', error);
        }
    };

    const ActionNetTermsButton = (
        <Dialog open={isNetTermDialogOpen} onOpenChange={setIsNetTermDialogOpen}>
            <DialogTrigger asChild>
                <Button
                    size="sm"
                    className="ml-4 h-8 text-sm"
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Net Term
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogTitle>Add Net Term</DialogTitle>
                <DialogDescription/>
                <Form {...netTermsForm}>
                    <form onSubmit={netTermsForm.handleSubmit(onSubmitNetTerms)} className="space-y-8">
                        <FormField
                            control={netTermsForm.control}
                            name="customer_ids"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Customers</FormLabel>
                                    <FormControl>
                                        <Controller
                                            name="customer_ids"
                                            control={netTermsForm.control}
                                            render={({ field }) => (
                                                <Select
                                                    isMulti
                                                    options={customers.map(customer => ({
                                                        value: customer.id,
                                                        label: customer.name
                                                    }))}
                                                    value={field.value.map(id => ({
                                                        value: id,
                                                        label: customers.find(c => c.id === id)?.name || id
                                                    }))}
                                                    onChange={(newValue) => {
                                                        field.onChange(newValue.map(v => v.value));
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormDescription>
                                        Select the customers for this net term
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={netTermsForm.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Net Term Name</FormLabel>
                                    <FormControl>
                                        <Select
                                            options={qbData.net_terms_options?.map(option => ({
                                                value: option.id,
                                                label: option.name
                                            })) || []}
                                            value={field.value ? { value: field.value, label: qbData.net_terms_options?.find(o => o.id === field.value)?.name || field.value } : null}
                                            onChange={(newValue) => {
                                                field.onChange(newValue ? newValue.label : '');
                                                if (newValue) {
                                                    netTermsForm.setValue('source_id', newValue.value);
                                                }
                                            }}
                                            isClearable
                                        />
                                    </FormControl>
                                    <FormDescription>
                                        Select a net term
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button type="submit">Submit</Button>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );

    const paymentTermFormSchema = z.object({
        allow_ach: z.boolean(),
        allow_credit_card: z.boolean(),
        custom_credit_card_processing_fee: z.string().regex(/^\d*\.?\d*$/, "Must be a valid number"),
        customer_ids: z.array(z.string()).nonempty("At least one customer must be selected"),
    });

    const paymentTermForm = useForm<z.infer<typeof paymentTermFormSchema>>({
        resolver: zodResolver(paymentTermFormSchema),
        defaultValues: {
            allow_ach: false,
            allow_credit_card: false,
            custom_credit_card_processing_fee: '0',
            customer_ids: [],
        },
    });

    const onSubmitPaymentTerm = async (values: z.infer<typeof paymentTermFormSchema>) => {
        try {
            const payloads = values.customer_ids.map(customerId => ({
                customer_id: customerId,
                accounting_system_id: qbAccounts[0]?.id,
                allow_ach: values.allow_ach,
                allow_credit_card: values.allow_credit_card,
                custom_credit_card_processing_fee: parseFloat(values.custom_credit_card_processing_fee),
            }));

            // @ts-ignore
            await wrapperFunction(putQbPaymentTermsPut, { requestBody: payloads });
            setIsPaymentTermDialogOpen(false);
            getPaymentTerms(); // Refresh the payment terms
        } catch (error) {
            console.error('Error submitting payment term:', error);
        }
    };

    const ActionPaymentTermsButton = (
        <Dialog open={isPaymentTermDialogOpen} onOpenChange={setIsPaymentTermDialogOpen}>
            <DialogTrigger asChild>
                <Button
                    size="sm"
                    className="ml-4 h-8 text-sm"
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Payment Term
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogTitle>Add Payment Term</DialogTitle>
                <DialogDescription/>
                <Form {...paymentTermForm}>
                    <form onSubmit={paymentTermForm.handleSubmit(onSubmitPaymentTerm)} className="space-y-8">
                        <FormField
                            control={paymentTermForm.control}
                            name="customer_ids"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Customers</FormLabel>
                                    <FormControl>
                                        <Controller
                                            name="customer_ids"
                                            control={paymentTermForm.control}
                                            render={({ field }) => (
                                                <Select
                                                    isMulti
                                                    options={customers.map(customer => ({
                                                        value: customer.id,
                                                        label: customer.name
                                                    }))}
                                                    value={field.value.map(id => ({
                                                        value: id,
                                                        label: customers.find(c => c.id === id)?.name || id
                                                    }))}
                                                    onChange={(newValue) => {
                                                        field.onChange(newValue.map(v => v.value));
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormDescription>
                                        Select the customers for this payment term
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={paymentTermForm.control}
                            name="allow_ach"
                            render={({ field }) => (
                                <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                                    <FormControl>
                                        <Checkbox
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <div className="space-y-1 leading-none">
                                        <FormLabel>
                                            Allow ACH
                                        </FormLabel>
                                        <FormDescription>
                                            Allow ACH payments
                                        </FormDescription>
                                    </div>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={paymentTermForm.control}
                            name="allow_credit_card"
                            render={({ field }) => (
                                <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                                    <FormControl>
                                        <Checkbox
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <div className="space-y-1 leading-none">
                                        <FormLabel>
                                            Allow Credit Card
                                        </FormLabel>
                                        <FormDescription>
                                            Allow credit card payments
                                        </FormDescription>
                                    </div>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={paymentTermForm.control}
                            name="custom_credit_card_processing_fee"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Custom Credit Card Processing Fee (%)</FormLabel>
                                    <FormControl>
                                        <Input type="number" step="0.001" {...field} />
                                    </FormControl>
                                    <FormDescription>
                                        Custom credit card processing fee (in percentage)
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button type="submit">Submit</Button>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );

    const reportFormatSchema = z.object({
        report_zero_charges: z.boolean(),
        report_kit_as_components: z.boolean(),
        email_to_cc_bill: z.string().email().optional().or(z.literal('')),
        customer_memo: z.string().max(1000).optional(),
        customer_ids: z.array(z.string()).nonempty("At least one customer must be selected"),
    });

    const reportFormatForm = useForm<z.infer<typeof reportFormatSchema>>({
        resolver: zodResolver(reportFormatSchema),
        defaultValues: {
            report_zero_charges: false,
            report_kit_as_components: false,
            email_to_cc_bill: '',
            customer_memo: '',
            customer_ids: [],
        },
    });
    const onSubmitReportFormat = async (values: z.infer<typeof reportFormatSchema>) => {
        try {
            const payloads = values.customer_ids.map(customerId => ({
                report_zero_charges: values.report_zero_charges,
                report_kit_as_components: values.report_kit_as_components,
                email_to_cc_bill: values.email_to_cc_bill || null,
                customer_memo: values.customer_memo || null,
                customer_id: customerId,
                accounting_system_id: qbAccounts[0]?.id,
            }));

            // @ts-ignore
            await wrapperFunction(putQbReportFormatPut, { requestBody: payloads });
            setIsReportFormatDialogOpen(false);
            getReportFormats(); // Refresh the report formats
        } catch (error) {
            console.error('Error submitting report format:', error);
        }
    };

    const ActionReportFormatButton = (
        <Dialog open={isReportFormatDialogOpen} onOpenChange={setIsReportFormatDialogOpen}>
            <DialogTrigger asChild>
                <Button
                    size="sm"
                    className="ml-4 h-8 text-sm"
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Report Format
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogTitle>Add Report Format</DialogTitle>
                <DialogDescription/>
                <Form {...reportFormatForm}>
                    <form onSubmit={reportFormatForm.handleSubmit(onSubmitReportFormat)} className="space-y-8">
                        <FormField
                            control={reportFormatForm.control}
                            name="customer_ids"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Customers</FormLabel>
                                    <FormControl>
                                        <Controller
                                            name="customer_ids"
                                                control={reportFormatForm.control}
                                            render={({ field }) => (
                                                <Select
                                                    isMulti
                                                    options={customers.map(customer => ({
                                                        value: customer.id,
                                                        label: customer.name
                                                    }))}
                                                    value={field.value.map(id => ({
                                                        value: id,
                                                        label: customers.find(c => c.id === id)?.name || id
                                                    }))}
                                                    onChange={(newValue) => {
                                                        field.onChange(newValue.map(v => v.value));
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormDescription>
                                        Select the customers for this report format
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={reportFormatForm.control}
                            name="report_zero_charges"
                            render={({ field }) => (
                                <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                                    <FormControl>
                                        <Checkbox
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <div className="space-y-1 leading-none">
                                        <FormLabel>
                                            Report Zero Charges
                                        </FormLabel>
                                        <FormDescription>
                                            Include zero charges in the report
                                        </FormDescription>
                                    </div>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={reportFormatForm.control}
                            name="report_kit_as_components"
                            render={({ field }) => (
                                <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                                    <FormControl>
                                        <Checkbox
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <div className="space-y-1 leading-none">
                                        <FormLabel>
                                            Report Kit As Components
                                        </FormLabel>
                                        <FormDescription>
                                            Report kit items as individual components
                                        </FormDescription>
                                    </div>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={reportFormatForm.control}
                            name="email_to_cc_bill"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email To CC Bill</FormLabel>
                                    <FormControl>
                                        <Input placeholder="email@example.com" {...field} />
                                    </FormControl>
                                    <FormDescription>
                                        Email address to CC on the bill
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={reportFormatForm.control}
                            name="customer_memo"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Customer Memo</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormDescription>
                                        Additional memo for the customer
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button type="submit">Submit</Button>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
    const customerMappingFormSchema = z.object({
        customer_mappings: z.array(z.object({
            customer_id: z.string().nonempty("Customer is required"),
            qb_customer_id: z.string().nonempty("QuickBooks customer is required"),
        }))
    });

    const customerMappingForm = useForm<z.infer<typeof customerMappingFormSchema>>({
        resolver: zodResolver(customerMappingFormSchema),
        defaultValues: {
            customer_mappings: customerMappings.length > 0 
                ? customerMappings.map(mapping => ({
                    customer_id: mapping.customer_id,
                    qb_customer_id: mapping.source_id
                  }))
                : [{ customer_id: '', qb_customer_id: '' }],
        },
    });

    useEffect(() => {
        if (customerMappings.length > 0) {
            customerMappingForm.reset({
                customer_mappings: customerMappings.map(mapping => ({
                    customer_id: mapping.customer_id,
                    qb_customer_id: mapping.source_id
                }))
            });
        }
    }, [customerMappings]);

    const { fields: customerMappingFields, append: appendCustomerMapping, remove: removeCustomerMapping } = useFieldArray({
        control: customerMappingForm.control,
        name: "customer_mappings"
    });
    const onSubmitCustomerMapping = async (values: z.infer<typeof customerMappingFormSchema>) => {
        try {
            // @ts-ignore
            await wrapperFunction(bulkDeleteQbCustomerMappingsBulkDelete, {
                requestBody: customerMappings.map(mapping => mapping.id)
            });

            const payloads = values.customer_mappings.map(mapping => ({
                customer_id: mapping.customer_id,
                accounting_system_id: qbAccounts[0]?.id,
                name: customers.find(c => c.id === mapping.customer_id)?.name || '',
                source_id: mapping.qb_customer_id
            }));

            for (const payload of payloads) {
                // @ts-ignore
                await wrapperFunction(postQbCustomerMappingsPost, {
                    requestBody: payload
                });
            }

            setIsCustomerMappingDialogOpen(false);
            getCustomerMappings();
        } catch (error) {
            console.error('Error submitting customer mapping:', error);
        }
    };

    const ActionCustomerMappingButton = (
        <Dialog open={isCustomerMappingDialogOpen} onOpenChange={setIsCustomerMappingDialogOpen}>
            <DialogTrigger asChild>
                <Button size="sm" className="ml-4 h-8 text-sm">
                    <Plus className="h-4 w-4 mr-2" />
                    Customer Mapping
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[625px]">
                <DialogTitle>Add Customer Mapping</DialogTitle>
                <DialogDescription>Map Rails customers to QuickBooks customers</DialogDescription>
                <Form {...customerMappingForm}>
                    <form onSubmit={customerMappingForm.handleSubmit(onSubmitCustomerMapping)} className="space-y-4">
                        {customerMappingFields.map((field, index) => (
                            <div key={field.id} className="flex items-center space-x-4">
                                <FormField
                                    control={customerMappingForm.control}
                                    name={`customer_mappings.${index}.customer_id`}
                                    render={({ field }) => (
                                        <FormItem className="flex-1">
                                            <FormLabel>Rails Customer</FormLabel>
                                            <Select
                                                options={customers.map(customer => ({
                                                    value: customer.id,
                                                    label: customer.name
                                                }))}
                                                value={field.value ? { value: field.value, label: customers.find(c => c.id === field.value)?.name || field.value } : null}
                                                onChange={(newValue) => field.onChange(newValue ? newValue.value : '')}
                                                isClearable
                                            />
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={customerMappingForm.control}
                                    name={`customer_mappings.${index}.qb_customer_id`}
                                    render={({ field }) => (
                                        <FormItem className="flex-1">
                                            <FormLabel>QuickBooks Customer</FormLabel>
                                            <Select
                                                options={qbData.customer_mappings?.map(customer => ({
                                                    value: customer.id,
                                                    label: customer.name
                                                })) || []}
                                                value={field.value ? { value: field.value, label: qbData.customer_mappings?.find(c => c.id === field.value)?.name || field.value } : null}
                                                onChange={(newValue) => field.onChange(newValue ? newValue.value : '')}
                                                isClearable
                                            />
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <Button 
                                    type="button" 
                                    onClick={() => removeCustomerMapping(index)}
                                    variant="ghost"
                                    className="p-0 h-10 w-10 self-end"
                                >
                                    <XCircleIcon className="h-5 w-5" />
                                </Button>
                            </div>
                        ))}
                        <div className="flex justify-between mt-6">
                            <Button type="button" onClick={() => appendCustomerMapping({ customer_id: '', qb_customer_id: '' })}>
                                Add Another Mapping
                            </Button>
                            <Button type="submit">Submit</Button>
                        </div>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );

    const serviceCategoryFormSchema = z.object({
        service_categories: z.array(z.object({
            accounting_system_id: z.string(),
            name: z.string(),
            invoice_category_mapping_name: z.string(),
            pricing_category_id: z.string(),
            source_id: z.string().optional(),
            product_id: z.string().nullable(),
        })),
    });

    useEffect(() => {
        if (serviceCategories.length > 0 && qbAccounts.length > 0) {
            serviceCategoryForm.reset({
                service_categories: serviceCategories.map(category => ({
                    accounting_system_id: qbAccounts[0]?.id || '',
                    name: category.name,
                    invoice_category_mapping_name: category.invoice_category_mapping_name,
                    pricing_category_id: category.pricing_category_id || '',
                    source_id: category.source_id || '',
                    product_id: category.product_id || null,
                }))
            });
        }
    }, [serviceCategories, qbAccounts]);

    const serviceCategoryForm = useForm<z.infer<typeof serviceCategoryFormSchema>>({
        defaultValues: {
            service_categories: serviceCategories.length > 0 && qbAccounts.length > 0
                ? serviceCategories.map(category => ({
                    accounting_system_id: qbAccounts[0]?.id || '',
                    name: category.name,
                    invoice_category_mapping_name: category.invoice_category_mapping_name,
                    pricing_category_id: category.pricing_category_id || '',
                    source_id: category.source_id || '',
                    product_id: category.product_id || null,
                }))
                : [{ accounting_system_id: qbAccounts[0]?.id || '', name: '', invoice_category_mapping_name: '', pricing_category_id: '', source_id: '', product_id: null }],
        },
    });

    const { fields: serviceCategoryFields, append: appendServiceCategory, remove: removeServiceCategory } = useFieldArray({
        control: serviceCategoryForm.control,
        name: "service_categories"
    });

    const onSubmitServiceCategory = async (values: z.infer<typeof serviceCategoryFormSchema>) => {
        try {

            // Explicitly type the argument to match the expected type
            const deletePayload = {
                requestBody: serviceCategories.map(mapping => mapping.id)
            };

            await wrapperFunction(bulkDeleteQbServiceCategoryBulkDelete, deletePayload);

            const payloads = values.service_categories.map(mapping => ({
                accounting_system_id: mapping.accounting_system_id,
                name: mapping.name,
                invoice_category_mapping_name: mapping.invoice_category_mapping_name,
                pricing_category_id: mapping.pricing_category_id,
                source_id: mapping.source_id,
                product_id: mapping.product_id
            }));

            for (const payload of payloads) {
                await wrapperFunction(postQbServiceCategoryPost, { requestBody: payload });
            }

            setIsServiceCategoryDialogOpen(false);
            getServiceCategories();
            
        } catch (error) {
            console.error('Error submitting service category:', error);
        }
    };

    const ActionServiceCategoryButton = (
        <Dialog open={isServiceCategoryDialogOpen} onOpenChange={setIsServiceCategoryDialogOpen}>
            <DialogTrigger asChild>
                <Button
                    size="sm"
                    className="ml-4 h-8 text-sm"
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Service Category
                </Button>
            </DialogTrigger>
    
            <DialogContent className="sm:max-w-[625px] max-h-[80vh] overflow-y-auto">
                <DialogTitle>Add Service Category</DialogTitle>
                <DialogDescription>Map Rails service categories to QuickBooks service categories</DialogDescription>
                <Form {...serviceCategoryForm}>
                    <form onSubmit={serviceCategoryForm.handleSubmit(onSubmitServiceCategory)} className="space-y-8">
                        <div className="space-y-4">
                            {serviceCategoryFields.map((field, index) => (
                                <div key={field.id} className="flex items-center space-x-4">
                                                                        <FormField
                                        control={serviceCategoryForm.control}
                                        name={`service_categories.${index}.name`}
                                        render={({ field }) => (
                                            <FormItem className="flex-1">
                                                <FormLabel>Rails Charge</FormLabel>
                                                <FormControl>
                                                    <Select
                                                        options={chargeTypes.map((chargeType: any) => ({
                                                            value: chargeType.charge_on_invoice,
                                                            label: chargeType.charge_on_invoice,
                                                            id: chargeType.id
                                                        }))}
                                                        value={field.value ? { value: field.value, label: field.value } : null}
                                                        onChange={(newValue) => {
                                                            field.onChange(newValue ? newValue.value : '');
                                                            const selectedChargeType = chargeTypes.find((c: any) => c.charge_on_invoice === newValue?.value);
                                                            if (selectedChargeType) {
                                                                serviceCategoryForm.setValue(`service_categories.${index}.pricing_category_id`, selectedChargeType.id);
                                                            }
                                                        }}
                                                        isClearable={false}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={serviceCategoryForm.control}
                                        name={`service_categories.${index}.invoice_category_mapping_name`}
                                        render={({ field }) => (
                                            <FormItem className="flex-1">
                                                <FormLabel>QuickBooks Service Category</FormLabel>
                                                <FormControl>
                                                    <Select
                                                        options={qbData.service_category_options?.map(category => ({
                                                            value: category.name,
                                                            label: category.name,
                                                            id: category.id
                                                        })) || []}
                                                        value={field.value ? { value: field.value, label: field.value } : null}
                                                        onChange={(newValue) => {
                                                            field.onChange(newValue ? newValue.value : '');
                                                            const selectedCategory = qbData.service_category_options?.find(c => c.name === newValue?.value);
                                                            if (selectedCategory) {
                                                                serviceCategoryForm.setValue(`service_categories.${index}.source_id`, selectedCategory.id);
                                                            }
                                                        }}
                                                        isClearable={false}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <Button 
                                        type="button" 
                                        onClick={() => removeServiceCategory(index)}
                                        variant="ghost"
                                        className="p-0 h-10 w-10 self-end mb-6"
                                    >
                                        <XCircleIcon className="h-5 w-5" />
                                    </Button>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-between mt-6">
                            <Button type="button" onClick={() => appendServiceCategory({ accounting_system_id: qbAccounts[0]?.id || '', name: '', invoice_category_mapping_name: '', pricing_category_id: '', source_id: '', product_id: null })}>
                                Add Another Service Category
                            </Button>
                            <Button type="submit">Submit</Button>
                        </div>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );

    const ConnectAccountingSystemButton = () => (
        <Button onClick={() => {
            // @ts-ignore
            wrapperFunction(getTokenQbGetTokenGet, {})
                .then((response: QBToken) => {
                    setIsQuickBooksSuccessDialogOpen(true);
                    getQbAccounts();
                })
                .catch((error) => {
                    console.error('Error connecting to QuickBooks:', error);
                });
        }}>
            <Plus className="mr-2 h-4 w-4" /> Accounting System
        </Button>
    );

    return (
        <div className="container mx-auto p-8">
            {qbAccounts.length === 0 ? (
                <div className="flex flex-col items-center justify-center h-[calc(100vh-200px)]">
                    <h2 className="text-2xl font-semibold mb-4">Connect your accounting system</h2>
                    <ConnectAccountingSystemButton />
                </div>
            ) : (
                <>
                    <h2 className="text-xl font-semibold mb-4">Net Terms</h2>
                    <DataTable 
                        columns={netTermColumns} 
                        data={groupedNetTermsData} 
                        loading={loading}
                        ActionButton={ActionNetTermsButton}
                        showActionButtonInToolbar={true}
                    />
                    <h2 className="text-xl font-semibold mb-4 mt-8">Payment Terms</h2>
                    <DataTable 
                        columns={paymentTermColumns} 
                        data={paymentTermGroupedData} 
                        loading={loading}
                        ActionButton={ActionPaymentTermsButton}
                        showActionButtonInToolbar={true}
                    />
                    <h2 className="text-xl font-semibold mb-4 mt-8">Report Formats</h2>
                    <DataTable 
                        columns={reportFormatColumns} 
                        data={reportFormatGroupedData} 
                        loading={loading}
                        ActionButton={ActionReportFormatButton}
                        showActionButtonInToolbar={true}
                    />
                    <h2 className="text-xl font-semibold mb-4 mt-8">Customer Mappings</h2>
                    <DataTable 
                        columns={customerMappingColumns} 
                        data={customerMappings} 
                        loading={loading}
                        ActionButton={ActionCustomerMappingButton}
                        showActionButtonInToolbar={true}
                    />
                    <h2 className="text-xl font-semibold mb-4 mt-8">Service Categories</h2>
                    <DataTable 
                        columns={serviceCategoryColumns} 
                        data={serviceCategories} 
                        loading={loading}
                        ActionButton={ActionServiceCategoryButton}
                        showActionButtonInToolbar={true}
                    />
                </>
            )}

            <Dialog open={isAccountingSystemDialogOpen} onOpenChange={setIsAccountingSystemDialogOpen}>
                <DialogContent>
                    <DialogTitle>Accounting System Integration</DialogTitle>
                    {/* Add form for accounting system integration here */}
                </DialogContent>
            </Dialog>

            <Dialog open={isQuickBooksSuccessDialogOpen} onOpenChange={setIsQuickBooksSuccessDialogOpen}>
                <DialogContent>
                    <DialogTitle>Success</DialogTitle>
                    <p>Your QuickBooks account has been successfully connected.</p>
                    <Button onClick={() => setIsQuickBooksSuccessDialogOpen(false)}>Close</Button>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AccountingSystems;