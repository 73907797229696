import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetFooter } from '../../common/sheet.tsx';

import { Button } from '@/components/ui/button';
import { Input } from '../../common/input.tsx';
import { ScrollArea } from '../../common/scroll-area.tsx';
import { Separator } from '../../common/separator.tsx';

import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '../../common/alert-dialog.tsx';

import ReceivingFeeEdit from './receivingFeesEdit';
import useReceivingApi from '../../../hooks/api/useReceivingApi';
import { ReceivingChargeType } from './forms/receivingFeesForm';
import { Switch } from '../../common/switch.tsx';

const ReceivingFeesView = ({ open, setOpen, selectedReceivingFee, customerData }) => {
    const { deleteReceivingFee } = useReceivingApi();
    const onDelete = (selectedReceivingFeeId) => {
        deleteReceivingFee(selectedReceivingFeeId).then((res) => {
            if (res.success) {
                setOpen(false);
                window.location.reload();
            }
        });
    };

    return (
        selectedReceivingFee && (
            <Sheet open={open} onOpenChange={setOpen}>
                <SheetContent position="right" size="lg">
                    <SheetHeader className="pb-36 overflow-y-auto h-full">
                        <SheetTitle className="text-3xl">{selectedReceivingFee.name}</SheetTitle>
                        <SheetDescription>View Receiving fee details below</SheetDescription>
                        <div className="pt-8">
                            <span className="flex text-xl font-medium">For all customers</span>
                            <div className="mt-2 flex items-center space-x-4">
                                <Switch checked={selectedReceivingFee.all_customers} disabled />
                            </div>
                        </div>
                        {selectedReceivingFee?.customers?.length > 0 && (
                            <div className="pt-8">
                                <span className="flex text-xl font-medium">Customers</span>
                                <ScrollArea className="overflow-y-auto max-h-72 rounded-md border px-4 mt-2 pb-2">
                                    {selectedReceivingFee.customers.map((customer, idx) => (
                                        <>
                                            <div className="w-100 pt-2 pr-2">{customer.name}</div>
                                            {idx !== selectedReceivingFee.customers.length - 1 && <Separator className="my-2" />}
                                        </>
                                    ))}
                                </ScrollArea>
                            </div>
                        )}
                        <div className="pt-8">
                            <span className="flex text-xl font-medium">Charging Rate</span>
                            <Input
                                className="bg-white w-72 mt-2"
                                type="text"
                                placeholder={ReceivingChargeType.find((type) => type.value === selectedReceivingFee?.charge_type)?.label}
                                disabled
                            />
                        </div>
                        <div className="pt-8">
                            <span className="flex text-xl font-medium">Charge Amount</span>
                            <Input className="bg-white w-72 mt-2" type="text" placeholder={`$${parseFloat(selectedReceivingFee?.charge_amount)?.toFixed(2)}`} disabled />
                        </div>
                    </SheetHeader>
                    <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                        <div>
                            <AlertDialog>
                                <AlertDialogTrigger asChild>
                                    <Button variant="outline">Delete</Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <AlertDialogTitle>Are you sure you want to delete {selectedReceivingFee.name}?</AlertDialogTitle>
                                        <AlertDialogDescription>This action cannot be undone. This will permanently delete the rule.</AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        <AlertDialogAction onClick={() => onDelete(selectedReceivingFee.id)}>Delete</AlertDialogAction>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                            <ReceivingFeeEdit selectedReceivingFee={selectedReceivingFee} customerData={customerData} />
                        </div>
                    </SheetFooter>
                </SheetContent>
            </Sheet>
        )
    );
};

export default ReceivingFeesView;
