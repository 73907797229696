import { useState, useEffect } from 'react';
import { Table, TableHeader, TableHead, TableBody, TableRow, TableCell } from '../../common/table.tsx';
import { Badge } from '../../common/badge.tsx';
import { wrapperFunction } from '../../../client/wrapperFunction.ts';
import { getOneInvoiceProductsProductIdGet } from '../../../client/services.gen.ts';

const KitComponentTable = ({ selectedProduct }) => {
    const [skus, setSkus] = useState({});

    useEffect(() => {
        const fetchSkus = async () => {
            const skuPromises = selectedProduct.kit_items.map((item) =>
                wrapperFunction(getOneInvoiceProductsProductIdGet, { productId: item.product_id }).then((res) => ({
                    id: item.product_id,
                    sku: res.sku,
                })),
            );

            const skuResults = await Promise.all(skuPromises);
            const skuMap = skuResults.reduce((acc, { id, sku }) => ({ ...acc, [id]: sku }), {});
            setSkus(skuMap);
        };
        if (selectedProduct && selectedProduct.is_kit) {
            fetchSkus();
        }
    }, []);

    return (
        selectedProduct && (
            <Table className="w-full">
                <TableHeader className="bg-slate-50">
                    <TableRow>
                        <TableHead>SKU</TableHead>
                        <TableHead>Quantity</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {selectedProduct && selectedProduct.kit_items
                        ? selectedProduct.kit_items.map((col) => (
                              <TableRow key={col.product_id}>
                                  <TableCell className="pr-2">
                                      <Badge variant="outline">{skus[col.product_id]}</Badge>
                                  </TableCell>
                                  <TableCell className="pr-2">
                                      <Badge variant="outline">{col.quantity}</Badge>
                                  </TableCell>
                              </TableRow>
                          ))
                        : []}
                </TableBody>
            </Table>
        )
    );
};

export default KitComponentTable;
