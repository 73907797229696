import { Input } from '../../../common/input.tsx';
import { Form, FormField, FormItem, FormControl, FormMessage } from '../../../common/form.tsx';

const EditChargeForm = ({ formProps }) => (
    <Form {...formProps}>
        <form className="space-y-8">
            <div className="pt-4">
                <div className="flex text-xl font-medium">Charge Type Name</div>
                <FormField
                    control={formProps.control}
                    name="charge_type_name"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input className="mt-2" placeholder="Charge Type Name" type="text" {...field} disabled />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>

            <div className="pt-4">
                <div className="flex text-xl font-medium">Old Charge Amount</div>
                <FormField
                    control={formProps.control}
                    name="old_amount"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input className="mt-2" placeholder="Old Charge Amount" type="number" {...field} disabled />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>

            <div className="pt-4">
                <div className="flex text-xl font-medium">New Charge Amount</div>
                <FormField
                    control={formProps.control}
                    name="amount"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input className="mt-2" type="number" {...field} onChange={(e) => field.onChange(parseFloat(e.target.value))} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </form>
    </Form>
);

export default EditChargeForm;
