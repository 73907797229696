import { useState, useEffect } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { storage } from '../../firebase';
import useStorage from '../../hooks/useStorage';

const fileTypes = ['JPEG', 'JPG', 'PNG', 'GIF'];

const UploadLogo = ({ companyId }) => {
    const { logoUrl } = useStorage({ companyId });
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        if (logoUrl) {
            setImageUrl(logoUrl);
        }
    }, [logoUrl]);

    const handleImageUpload = (image) => {
        if (image && companyId) {
            // Add a check for companyId
            const storageRef = storage.ref(`logo/${companyId}`);
            const uploadTask = storageRef.put(image);

            uploadTask.on('state_changed', () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    setImageUrl(downloadURL);
                });
            });
        }
    };

    return (
        <div>
            <FileUploader handleChange={handleImageUpload} name="file" types={fileTypes} />
            {imageUrl && (
                <>
                    <h5 className="text-xs leading-6 text-rails-dark-blue">Current Logo</h5>
                    <img src={imageUrl} alt="Uploaded Logo" style={{ maxWidth: '70px' }} />
                </>
            )}
        </div>
    );
};

export default UploadLogo;
