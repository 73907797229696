import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Toaster } from './components/common/toaster';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/auth/login';
import AccountSettings from './components/accountSettings/settings';
import Dashboard from './components/home/dashboard';
import Customers from './components/customers/customers';
import Customer from './components/customers/customer';
import Orders from './components/orders/orders';
import NotFound from './components/common/NotFound';
import PricingRules from './components/invoices/pricingRules/pricingRules';
import Products from './components/invoices/products/products';
import Packages from './components/invoices/packages/packages';
import Configurations from './components/invoices/configurations/configs';
import InvoiceOverview from './components/invoices/overview/invoiceOverview';
import Locations from './components/storage/locations/locations';
import StorageFees from './components/storage/storageFees/storageFees';
import NavBar from './components/navBar';
import ScrollToHashElement from './components/common/scrollToHashElement';
import StorageTypes from './components/storage/storageTypes/storageTypes';
import ReceivingFees from './components/receiving/receivingFees/receivingFees';
import CarrierOverview from './components/billing/carriers/carrierOverview';
import CarrierReport from './components/reports/carrier';
import InvoiceInsights from './components/invoices/invoiceInsights';
import PostageReconciliation from './components/billing/carriers/postageReconciliation';
import SingleInvoiceDetail from './components/invoices/overview/singleInvoiceDetail';
import AccessorialCharges from './components/billing/carriers/accessorialCharges';
import AllCarrierBills from './components/billing/carriers/allCarrierBills';
import ChargeLevelDetails from './components/billing/carriers/chargeLevelDetails';
import WmsIntegrations from './components/integrations/wms';
import CarrierIntegrations from './components/integrations/carriers';
import AccountingSystems from './components/integrations/accounting-systems';

function App() {
    return (
        <>
            <Toaster />
            <Router>
                <ScrollToHashElement />
                <AuthProvider>
                    <Routes>
                        <Route path="*" element={<NotFound />} />

                        <Route element={<NavBar />}>
                            <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
                            <Route path="/customers" element={<ProtectedRoute component={Customers} />} />
                            <Route path="/customers/:customerId" element={<ProtectedRoute component={Customer} />} />
                            <Route path="/orders" element={<ProtectedRoute component={Orders} />} />
                            <Route path="/integrations/wms" element={<ProtectedRoute component={WmsIntegrations} />} />
                            <Route path="/integrations/carriers" element={<ProtectedRoute component={CarrierIntegrations} />} />
                            <Route path="/integrations/accounting-systems" element={<ProtectedRoute component={AccountingSystems} />} />
                            <Route path="/settings" element={<ProtectedRoute component={AccountSettings} />} />
                            <Route path="/carriers/reports" element={<ProtectedRoute component={CarrierReport} />} />
                            <Route path="/carriers/bill-analysis" element={<ProtectedRoute component={CarrierOverview} />} />
                            <Route path="/carriers/postage-reconciliation" element={<ProtectedRoute component={PostageReconciliation} />} />
                            <Route path="/carriers/accessorial-charges" element={<ProtectedRoute component={AccessorialCharges} />} />
                            <Route path="/carriers/all-carrier-bills" element={<ProtectedRoute component={AllCarrierBills} />} />
                            <Route path="/carriers/charge-level-details" element={<ProtectedRoute component={ChargeLevelDetails} />} />
                            <Route path="/invoices/insights" element={<ProtectedRoute component={InvoiceInsights} />} />
                            <Route path="/invoices/overview" element={<ProtectedRoute component={InvoiceOverview} />} />
                            <Route path="/invoices/:invoiceId" element={<ProtectedRoute component={SingleInvoiceDetail} />} />
                            <Route path="/invoices/pricing/client-charges" element={<ProtectedRoute component={PricingRules} />} />
                            <Route path="/invoices/pricing/products" element={<ProtectedRoute component={Products} />} />
                            <Route path="/invoices/pricing/packages" element={<ProtectedRoute component={Packages} />} />
                            <Route path="/invoices/configurations" element={<ProtectedRoute component={Configurations} />} />
                            <Route path="/storage/types" element={<ProtectedRoute component={StorageTypes} />} />
                            <Route path="/storage/locations" element={<ProtectedRoute component={Locations} />} />
                            <Route path="/storage/fees" element={<ProtectedRoute component={StorageFees} />} />
                            <Route path="/receiving/fees" element={<ProtectedRoute component={ReceivingFees} />} />
                        </Route>
                        <Route path="/" element={<Login />} />
                    </Routes>
                </AuthProvider>
            </Router>
        </>
    );
}

export default App;
