import type { CancelablePromise } from './core/CancelablePromise';
import { toast } from '../components/common/use-toast.tsx';
import type { ApiRequestOptions } from './core/ApiRequestOptions';
import Cookies from 'universal-cookie';
import { OpenAPI } from './core/OpenAPI';
import { ApiError } from './core/ApiError.ts';

// Wrapper function for API calls
export async function wrapperFunction(apiCall: (data: ApiRequestOptions) => CancelablePromise<any>, data: ApiRequestOptions) {
    try {
        // Load the headers including authorization token
        await loadHeader();

        // Make the API call and return the result
        return await apiCall(data);
    } catch (error: any) {
        // Check if the error is an instance of ApiError
        if (error instanceof ApiError) {
            switch (error.status) {
                case 401:
                    toast({
                        variant: 'destructive',
                        title: 'Unauthorized ' + error.status,
                        description: 'Please log in again.',
                    });
                    break;
                case 403:
                    toast({
                        variant: 'destructive',
                        title: 'Forbidden ' + error.status,
                        description: 'You do not have access to this resource.',
                    });
                    break;
                case 404:
                    toast({
                        variant: 'destructive',
                        title: 'Not Found ' + error.status,
                        description: 'The requested resource could not be found.',
                    });
                    break;
                case 500:
                    toast({
                        variant: 'destructive',
                        title: 'Server Error ' + error.status,
                        description: 'An internal server error occurred. Please try again later.',
                    });
                    break;
                case 422:
                    toast({
                        variant: 'destructive',
                        title: error.statusText + ' ' + error.status,
                        description: error.message,
                    });
                    break;
                default:
                    toast({
                        variant: 'destructive',
                        title: error.statusText + ' ' + error.status,
                    });
                    break;
            }
        } else {
            // Handle unknown errors
            console.error('Unknown Error: ', error);
            toast({
                variant: 'destructive',
                title: 'Unknown Error',
                description: 'An unknown error occurred. Please try again.',
            });
        }
    }
}

// Function to load the authorization token and set it in the headers
async function loadHeader() {
    let token = getAuthToken();

    if (!token) {
        token = await refreshAuthToken(); // Handle token refresh if no valid token is found
    }

    // Add the authorization token to the request headers
    OpenAPI.interceptors.request.use(async (request: RequestInit) => {
        request.headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
        return request;
    });
}

// Function to get the current auth token from cookies
const getAuthToken = () => {
    const cookies = new Cookies();
    const token = cookies.get('auth_token');
    return token;
};

// Function to refresh the auth token if the current one is invalid or expired
async function refreshAuthToken() {
    const refreshToken = getRefreshToken();

    if (!refreshToken) {
        throw new Error('No refresh token available');
    }

    // API call to refresh the token using the refresh token
    const response = await fetch('/auth/refresh-token', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh_token: refreshToken }),
    });

    if (response.ok) {
        const data = await response.json();
        const newToken = data.access_token;

        // Store the new token in cookies
        const cookies = new Cookies();
        cookies.set('auth_token', newToken);

        return newToken;
    } else {
        throw new Error('Unable to refresh token');
    }
}

// Function to get the refresh token from cookies
const getRefreshToken = () => {
    const cookies = new Cookies();
    return cookies.get('refresh_token');
};
