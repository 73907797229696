import './styles/index.css';
import HyperDX from '@hyperdx/browser';
import App from './App';
import { backendUri } from './consts/vars';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

// Initialize HyperDX
try {
    HyperDX.init({
        // @ts-ignore
        apiKey: import.meta.env.VITE_APP_HYPERDX_APIKEY,
        service: 'rails-web-platform',
        tracePropagationTargets: [backendUri],
        consoleCapture: true,
        advancedNetworkCapture: true,
    });
    HyperDX.enableAdvancedNetworkCapture();
} catch (error) {
    console.error('Failed to initialize HyperDX:', error);
}

// Render the React app
// @ts-ignore
const rootElement = document.getElementById('root');
if (rootElement) {
    createRoot(rootElement).render(
        <StrictMode>
            <App />
        </StrictMode>
    );
} else {
    console.error('Root element not found');
}
