import * as z from 'zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetFooter } from '../../common/sheet.tsx';
import PricingCategoryForm from './forms/pricingCategoryForm.jsx';
import useInvoiceApi from '../../../hooks/api/useInvoiceApi.js';

const PricingCategoryCreate = ({ chargeLevels, isDialogOpen, setIsDialogOpen }) => {
    const [loading, setLoading] = useState(false);
    const pricingCategoryCreateFormDefaultValues = {
        name: '',
        charge_on_invoice: '',
        description: '',
        default: false,
        required: false,
        charge_level: '',
    };
    const { createInvoiceChargeType } = useInvoiceApi();

    const dropDownSchema = z.object({
        label: z.string(),
        value: z.string(),
    });

    const pricingCategorySchema = z.object({
        name: z.string().trim().min(3, { message: 'Minimum of 3 characters required' }),
        charge_on_invoice: z.string().trim().min(3, { message: 'Minimum of 3 characters required' }),
        default: z.boolean().optional(),
        required: z.boolean().optional(),
        description: z.string().trim().min(3, { message: 'Minimum of 3 characters required' }),
        charge_level: dropDownSchema,
    });

    const formProps = useForm({
        resolver: zodResolver(pricingCategorySchema),
        defaultValues: pricingCategoryCreateFormDefaultValues,
    });

    const { handleSubmit, reset } = formProps;

    const onSubmit = (values) => {
        setLoading(true);
        createInvoiceChargeType(values).then(async (resp) => {
            if (resp) {
                window.location.reload();
            }
        });
    };

    return (
        <Sheet open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <SheetContent position="right" size="lg">
                <SheetHeader className="pb-36 overflow-y-auto h-full">
                    <SheetTitle className="text-3xl">Create New Pricing Category</SheetTitle>
                    <SheetDescription className="text-rails-dark-blue">
                        <PricingCategoryForm formProps={formProps} chargeLevels={chargeLevels} />
                    </SheetDescription>
                </SheetHeader>
                <SheetFooter
                    className={`${loading ? 'animate-pulse disabled' : ' '} border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8`}
                >
                    <div>
                        <Button onClick={handleSubmit(onSubmit)} type="submit">
                            {loading ? 'Creating' : 'Create'}
                        </Button>
                    </div>
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
};

export default PricingCategoryCreate;
