import Select from 'react-select';
import { Input } from '../../../common/input.tsx';
import { Form, FormField, FormItem, FormControl, FormMessage } from '../../../common/form.tsx';

const AddLineChargeForm = ({ formProps, invoiceChargeTypes }) => (
    <Form {...formProps}>
        <form className="space-y-8">
            <div className="pt-4">
                <div className="flex text-xl font-medium">Order Number</div>
                <FormField
                    control={formProps.control}
                    name="order_number"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input className="mt-2" placeholder="Order Number" type="text" {...field} disabled />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-medium">Charge Name</div>
                <div className="mt-2">
                    <FormField
                        control={formProps.control}
                        name="charge_type_id"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Select
                                        {...field}
                                        options={invoiceChargeTypes}
                                        placeholder="Charge Category"
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#DBF3D8',
                                                primary: '#92BAA3',
                                            },
                                        })}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </div>

            <div className="pt-4">
                <div className="flex text-xl font-medium">Charge Amount</div>
                <FormField
                    control={formProps.control}
                    name="amount"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input className="mt-2" type="number" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>

            <div className="pt-4">
                <div className="flex text-xl font-medium">Total Quantity</div>
                <FormField
                    control={formProps.control}
                    name="quantity"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input className="mt-2" type="number" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>

            <div className="pt-4">
                <div className="flex text-xl font-medium">Description</div>
                <FormField
                    control={formProps.control}
                    name="description"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input className="mt-2" type="text" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </form>
    </Form>
);

export default AddLineChargeForm;
