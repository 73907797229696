import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import HyperDX from '@hyperdx/browser';
import { fire, auth, db } from '../firebase';
import useFetch from '../hooks/useFetch';

const AuthContext = React.createContext();

const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const { makeApiCall } = useFetch();

    const [currentUser, setCurrentUser] = useState();
    const [currentUserCompany, setCurrentUserCompany] = useState();
    const [token, setToken] = useState();
    const [loadingAuth, setLoadingAuth] = useState(true);

    const reauthenticate = (currentPassword) => {
        const credential = fire.auth.EmailAuthProvider.credential(currentUser.email, currentPassword);
        return currentUser.reauthenticateWithCredential(credential);
    };

    const handleSessionExpiration = () => {
        // Clear user data
        setCurrentUser(null);
        setCurrentUserCompany(null);
        setToken(null);
        // Clear cookies
        cookies.remove('auth_token', { path: '/' });
        cookies.remove('user_company_name', { path: '/' });
        cookies.remove('user_info', { path: '/' });
        // Redirect to login page
        navigate('/', { state: { sessionExpired: true } });
    };
    /*
        get firebase auth token (used to authenticate with the backend server)
        and setup interval to refresh token 600,000ms or 10 mins before it expires
    */
    const getFirebaseToken = () => {
        fire.auth()
            .currentUser.getIdToken(true)
            .then((idToken) => {
                cookies.set('auth_token', idToken, { path: '/' });
                setToken(idToken);
                const refreshInterval = fire.auth().currentUser.multiFactor.user.stsTokenManager.expirationTime - Date.now();
                const interval_id = setInterval(() => {
                    setLoadingAuth(true);
                    fire.auth()
                        .currentUser.getIdToken(true)
                        .then((refreshIdToken) => {
                            cookies.set('auth_token', refreshIdToken, { path: '/' });
                            setToken(idToken);
                            setLoadingAuth(false);
                        })
                        .catch((error) => {
                            console.error('Error refreshing token', error);
                            clearInterval(interval_id);
                            handleSessionExpiration();
                        });
                }, refreshInterval - 600000);
            })
            .catch((error) => {
                console.error('Error getting token', error);
                handleSessionExpiration();
            });
    };

    const handleLogOut = async () => {
        await auth.signOut();
        navigate('/');
        cookies.set('auth_token', '', { path: '/' });
        cookies.set('user_company_name', '', { path: '/' });
    };

    // init user with firebase
    const initUser = async () => {
        fire.auth().onAuthStateChanged(async (user) => {
            if (user) {
                setLoadingAuth(true);
                setCurrentUser(user);
                cookies.set('user_info', user, { path: '/' });

                await db
                    .collection('users')
                    .where('user_id', '==', user.uid)
                    .get()
                    .then((querySnapshot) => {
                        if (!querySnapshot.size) {
                            throw new Error('User not found');
                        }
                        querySnapshot.forEach((doc) => {
                            setCurrentUserCompany(doc.data().company_name);
                            cookies.set('user_company_name', doc.data().company_name, { path: '/' });
                        });
                    })
                    .catch((error) => {
                        handleLogOut();
                    });

                setLoadingAuth(false);
            } else {
                setLoadingAuth(false);
            }
        });
    };

    const login = async (email, password) => {
        const authInfo = await auth.signInWithEmailAndPassword(email, password).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
        });
        const user = authInfo?.user;

        if (user) {
            setLoadingAuth(true);
            await initUser();

            HyperDX.setGlobalAttributes({
                userId: user.uid,
                userEmail: user.email,
                userName: user.displayName,
            });
        } else {
            HyperDX.setGlobalAttributes(null);
        }

        return authInfo;
    };

    const logout = () => handleLogOut();
    const resetPassword = (email) => auth.sendPasswordResetEmail(email);

    const updatePassword = (currentPassword, newPassword) =>
        reauthenticate(currentPassword).then(() => {
            currentUser.updatePassword(newPassword);
        });

    useEffect(() => {
        if (currentUser) {
            getFirebaseToken();
        }
        initUser();
    }, [currentUser]);

    const value = useMemo(
        () => ({
            loadingAuth,
            token,
            currentUser,
            currentUserCompany,
            login,
            logout,
            resetPassword,
            updatePassword,
        }),
        [loadingAuth, token, currentUser, currentUserCompany],
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthProvider, useAuth };
