import React from 'react';
import { storage } from '../firebase';

const useStorage = ({ companyId }) => {
    const [logoUrl, setLogoUrl] = React.useState('');
    const [error, setError] = React.useState(null);

    const fetchLogoPicture = async () => {
        try {
            const storageRef = storage.ref(`logo/${companyId}`);
            const url = await storageRef.getDownloadURL();
            return url;
        } catch (err) {
            setError(err.message);
            return null;
        }
    };

    React.useEffect(() => {
        if (companyId) {
            fetchLogoPicture().then((url) => {
                if (url) {
                    setLogoUrl(url);
                }
            });
        }
    }, [companyId]);

    return { logoUrl, error };
};

export default useStorage;
