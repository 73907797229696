import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import LoadingSpinner from './common/loadingSpinner';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { loadingAuth, token } = useAuth();
    const location = useLocation();

    return (
        <>
            <LoadingSpinner loading={loadingAuth} />
            {!loadingAuth && token && <Component />}
            {!loadingAuth && !token && <Navigate to={`/login?redirect=${location.pathname}`} />}
        </>
    );
};

export default ProtectedRoute;
