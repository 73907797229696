/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { DataTable } from '../../common/datatable/dataTable.tsx';
import { DataTableColumnHeader } from '../../common/datatable/dataTableColumnHeader.tsx';
import useInvoiceApi from '../../../hooks/api/useInvoiceApi';
import InvoiceDetailView from './invoiceDetailView';
import { Tabs, TabsContent } from '../../common/tabs.tsx';
import InvoiceCreate from './invoiceCreate';
import { getInvoiceSummaryInvoiceInvoiceSummariesGet } from '../../../client/services.gen.ts';
import { wrapperFunction } from '../../../client/wrapperFunction.ts';

const invoiceColumns = [
    {
        accessorKey: 'customer_name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Customer" />,
        cell: ({ row }) => (
            <div className="w-[200px] font-medium hover:underline">
                <a href="#">{row.getValue('customer_name')}</a>
            </div>
        ),
        enableSorting: true,
        enableHiding: false,
    },
    {
        accessorKey: 'invoice_id',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Invoice ID" />,
        cell: ({ row }) => (
            <div className="flex flex-wrap space-x-2">
                <span className="max-w-[500px] text-wrap">{row.getValue('invoice_id')}</span>
            </div>
        ),
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: 'billing_period_start',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Billing Period Start" />,
        cell: ({ row }) => (
            <div className="flex flex-wrap space-x-2">
                <span className="max-w-[500px] text-wrap">{row.getValue('billing_period_start').substring(0, 10)}</span>
            </div>
        ),
        enableSorting: true,
        enableHiding: false,
    },
    {
        accessorKey: 'billing_period_end',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Billing Period End" />,
        cell: ({ row }) => (
            <div className="flex flex-wrap space-x-2">
                <span className="max-w-[500px] text-wrap">{row.getValue('billing_period_end').substring(0, 10)}</span>
            </div>
        ),
        enableSorting: true,
        enableHiding: false,
    },
    {
        accessorKey: 'total_invoice_amount',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Total Invoice Amount" />,
        cell: ({ row }) => (
            <div className="flex flex-wrap space-x-2">
                <span className="max-w-[500px] text-wrap">
                    <NumericFormat value={row.getValue('total_invoice_amount')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
                </span>
            </div>
        ),
        enableSorting: true,
        enableHiding: false,
    },
];

const DataTableComponent = ({ isLoading, invoiceTableData, handleRowClick, customerData }) => (
    <DataTable
        loading={isLoading}
        data={invoiceTableData}
        columns={invoiceColumns}
        rowOnClick={handleRowClick}
        ActionButton={<InvoiceCreate customerData={customerData} />}
        isMultiSelectRows={false}
        isDownloadable
        showActionButtonInToolbar
        tableName="invoices"
    />
);

const InvoiceDetailViewComponent = ({ invoiceOpen, setInvoiceOpen, selectedInvoice }) => (
    <InvoiceDetailView open={invoiceOpen} setOpen={setInvoiceOpen} invoiceData={selectedInvoice} />
);

const InvoiceOverview = ({ filter = null, dataTableOnly = false }) => {
    const { getInvoiceCustomers, getInvoiceSummaryByCompany } = useInvoiceApi();
    const [isLoading, setIsLoading] = useState(false);
    const [invoiceTableData, setInvoiceTableData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [invoiceOpen, setInvoiceOpen] = useState(false);

    const fetchCustomers = async () => {
        const res = await getInvoiceCustomers();
        const filteredData = res.data
            .filter((customer) => customer.active === true)
            .filter((customer) => customer.invoice_customer_id === null)
            .map((item) => ({
                label: item.name,
                value: item.id,
            }));
        setCustomerData(filteredData);
    };

    useEffect(() => {
        setIsLoading(true);
        Promise.all([wrapperFunction(getInvoiceSummaryInvoiceInvoiceSummariesGet), fetchCustomers()]).then((res) => {
            // Sort the invoice data before setting it to state
            const sortedData = res[0].sort((a, b) => {
                const dateA = new Date(a.billing_period_end);
                const dateB = new Date(b.billing_period_end);
                if (!isNaN(dateA) && !isNaN(dateB)) {
                    return dateB.getTime() - dateA.getTime();
                }
                return 0;
            });
            setInvoiceTableData(sortedData);
            setIsLoading(false);
        });
    }, []);

    const handleRowClick = (row) => {
        const selectedInvoiceItem = invoiceTableData.find((data) => data.invoice_id === row.original.invoice_id);
        setSelectedInvoice(selectedInvoiceItem);
        setInvoiceOpen(true);
    };

    if (dataTableOnly) {
        return (
            <>
                <DataTableComponent isLoading={isLoading} invoiceTableData={invoiceTableData} handleRowClick={handleRowClick} customerData={customerData} />
                <InvoiceDetailViewComponent invoiceOpen={invoiceOpen} setInvoiceOpen={setInvoiceOpen} selectedInvoice={selectedInvoice} />
            </>
        );
    }

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">Overview</h1>
                            <h2 className="text-xs text-rails-dark-blue">Get a complete overview of billing activity in the last 60 days through Rails.</h2>
                        </div>

                        <div className="mt-12 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <Tabs defaultValue="invoices">
                                <TabsContent value="invoices">
                                    <div className="pt-4">
                                        <div>
                                            <div className="container mx-auto py-4">
                                                {invoiceTableData && (
                                                    <DataTableComponent
                                                        isLoading={isLoading}
                                                        invoiceTableData={invoiceTableData}
                                                        handleRowClick={handleRowClick}
                                                        customerData={customerData}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </TabsContent>
                            </Tabs>
                        </div>
                    </div>
                </main>
            </div>

            <InvoiceDetailViewComponent invoiceOpen={invoiceOpen} setInvoiceOpen={setInvoiceOpen} selectedInvoice={selectedInvoice} />
        </div>
    );
};

export default InvoiceOverview;
