import { useEffect, useState } from 'react';
// needed to setup chartjs context
import 'chart.js/auto';

import { useAuth } from '../../context/AuthContext';
import useGeneralApi from '../../hooks/api/useGeneralApi';

const doughnutOptions = {
    plugins: {
        title: {
            display: true,
            text: 'Order Carrier Distribution (last 14 days)',
            padding: {
                top: 5,
                bottom: 5,
            },
        },
        legend: {
            position: 'left',
            labels: {
                padding: 5,
            },
        },
    },
    layout: {
        padding: 20,
    },
    maintainAspectRatio: false,
};

const barOptions = {
    plugins: {
        title: {
            display: true,
            text: 'Carrier Service Level Delivery Times (in days)',
            padding: {
                top: 5,
                bottom: 10,
            },
        },
        legend: {
            position: 'bottom',
            labels: {
                padding: 10,
            },
        },
    },
    layout: {
        padding: 20,
    },
    responsive: true,
    maintainAspectRatio: false,
};

const monthlyShipmentBarOptions = {
    plugins: {
        title: {
            display: true,
            text: 'Monthly Shipment Volume (last 4 months)',
            padding: {
                top: 5,
                bottom: 20,
            },
        },
        legend: {
            position: 'bottom',
            labels: {
                padding: 10,
            },
        },
    },
    layout: {
        padding: 20,
    },
    responsive: true,
    maintainAspectRatio: false,
};

const Dashboard = () => {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const { getViewData } = useGeneralApi();

    const [doughnutDataState, setDoughnutDataState] = useState(null);
    const [carrierDeliveryTimeAllTime, setCarrierDeliveryTimeAllTime] = useState(null);
    const [shipmentAnalyticsData, setShipmentAnalyticsData] = useState(null);
    // const [geoJsonMapData, setGeoJsonMapData] = useState(null);
    // const [longLatDescription, setlongLatDescription] = useState(null);
    // const [carrierOptions, setCarrierOptions] = useState(null);
    const [carrierPicked, setCarrierPicked] = useState(null);
    const [monthlyShipmentBarDataState, setMonthlyShipmentBarDataState] = useState(null);
    // const [barData, setBarData] = useState(null);
    const [carrierBillMonthlyAnalyticsData, setCarrierBillMonthlyAnalyticsData] = useState(null);
    // const [totalCarrierOptions, setTotalCarrierOptions] = useState(null);
    const [totalCarrierPicked, setTotalCarrierPicked] = useState(null);
    // const [totalSpendSurchargeData, setTotalSpendSurchargeData] = useState(null);
    const [shipDateDeltaData, setShipDateDeltaData] = useState(null);

    useEffect(() => {
        if (currentUser.uid !== null) {
            setLoading(false);
        }
    }, [currentUser.uid]);

    // useEffect(() => {
    //     const uniqueCarrierArr = uniq(carrierDeliveryTimeAllTime?.map((i) => i.carrier));
    //     setCarrierOptions(uniqueCarrierArr.map((c, i) => ({ id: i, carrier: c })));
    //     setCarrierPicked(uniqueCarrierArr[0]);
    // }, [carrierDeliveryTimeAllTime]);

    // const handleCarrierDropDownChange = (e) => {
    //     setCarrierPicked(e.target.value);
    // };

    // useEffect(() => {
    //     const carrierFilterData = carrierDeliveryTimeAllTime?.filter((x) => x.carrier === carrierPicked);
    //     const shipmentMonths = uniq(carrierFilterData?.map((x) => x.shipment_m));
    //     const serviceLevels = uniq(carrierFilterData?.map((x) => x.service_level));
    //     const gradientArray = new Gradient().setColorGradient(RAILS_MED_GREEN, RAILS_DARK_BLUE).setMidpoint(serviceLevels.length).getColors();

    //     const deliveryTimeDatasets = serviceLevels?.map((sl, i) => {
    //         const slData = carrierFilterData?.filter((x) => x.service_level === sl);
    //         return {
    //             label: sl,
    //             data: slData.map((x) => x.day_diff),
    //             backgroundColor: gradientArray[i],
    //             tension: 0.1,
    //         };
    //     });

    //     const deliveryTimeLabels = shipmentMonths.map((m) => MONTH_LABELS[m]);

    //     setBarData({
    //         datasets: deliveryTimeDatasets,
    //         labels: deliveryTimeLabels,
    //     });
    // }, [carrierPicked, carrierDeliveryTimeAllTime]);

    //   var geojson = {
    //     "name":"NewFeatureType",
    //     "type":"FeatureCollection",
    //     "features":[{
    //         "type":"Feature",
    //         "geometry":{
    //             "type":"LineString",
    //             "coordinates":[]
    //         },
    //         "properties":null
    //     }]
    // };

    // useEffect(() => {
    //     const uniqCarrierOptions = uniq(carrierBillMonthlyAnalyticsData?.map((x) => x.carrier));
    //     setTotalCarrierOptions(uniqCarrierOptions.map((x, i) => ({ id: i, carrier: x })));
    //     setTotalCarrierPicked(uniqCarrierOptions[0]);
    // }, [carrierBillMonthlyAnalyticsData]);

    // useEffect(() => {
    //     const filteredTotalData = carrierBillMonthlyAnalyticsData?.filter((x) => x.carrier === totalCarrierPicked);

    //     setTotalSpendSurchargeData({
    //         datasets: [
    //             {
    //                 label: 'Total Spend',
    //                 data: filteredTotalData?.map((x) => x.total_spend),
    //                 borderColor: RAILS_MED_GREEN,
    //                 tension: 0.1,
    //             },
    //             {
    //                 label: 'Total Surcharge',
    //                 data: filteredTotalData?.map((x) => x.total_surcharge),
    //                 borderColor: RAILS_DARK_BLUE,
    //                 tension: 0.1,
    //             },
    //         ],
    //         labels: filteredTotalData?.map((x) => MONTH_LABELS[x.shipment_month]),
    //     });
    // }, [totalCarrierPicked, carrierBillMonthlyAnalyticsData]);

    // const avgWeekdayDeliveryDaysData = carrierBillMonthlyAnalyticsData.map()
    // const avgWeekdayDeliveryDaysShipmentMonths = uniq(carrierBillMonthlyAnalyticsData?.map((x) => x.shipment_month));
    // const avgWeekdayDeliveryDaysCarriers = uniq(carrierBillMonthlyAnalyticsData?.map((x) => x.carrier));
    // const gradientArrayDeliveryDays = new Gradient().setColorGradient(RAILS_MED_GREEN, RAILS_DARK_BLUE).setMidpoint(avgWeekdayDeliveryDaysCarriers.length).getColors();

    // const avgWeekdayDeliveryDaysDatasets = avgWeekdayDeliveryDaysCarriers?.map((carrier, i) => {
    //     const carrierData = carrierBillMonthlyAnalyticsData?.filter((x) => x.carrier === carrier);
    //     return {
    //         label: carrier,
    //         data: carrierData.map((x) => x.avg_weekday_delivery_days),
    //         borderColor: gradientArrayDeliveryDays[i],
    //         tension: 0.1,
    //     };
    // });

    // const avgShipDateDeltaCarriers = uniq(shipDateDeltaData?.map((x) => x.carrier));
    // const gradientArray = new Gradient().setColorGradient(RAILS_MED_GREEN, RAILS_DARK_GREEN).setMidpoint(avgShipDateDeltaCarriers.length).getColors();

    /*
  
  <div className="space-x-8 ml-8 mt-8 mr-8">
                <div className="p-4 bg-white rounded-xl shadow-lg">
                  {!!geoJsonMapData && geoJsonMapData.features?.length > 0 && (
                    <MapView geoJsonData={geoJsonMapData} longLatDescription={longLatDescription} />
                  )}
                </div>
              </div>
              
              {!!barData && (
                <div className="grid grid-cols-1 h-auto space-x-8 ml-8 mt-8 mr-8">
                  <div className="w-full h-96 p-10 bg-white rounded-xl shadow-lg">
                    <DropDownList listOfOptions={carrierOptions} handleChange={handleCarrierDropDownChange}/>
                    <Bar data={barData} options={barOptions} />
                  </div>
                </div>
              )}


        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6">
              <div className="space-x-8 ml-8 mt-8 mr-8">
                <div className="p-4 bg-white rounded-xl shadow-lg">
                  {!!geoJsonMapData && geoJsonMapData.features?.length > 0 && (
                    <MapView geoJsonData={geoJsonMapData} longLatDescription={longLatDescription} />
                  )}
                </div>
              </div>
              
              {!!barData && (
                <div className="grid grid-cols-1 h-auto space-x-8 ml-8 mt-8 mr-8">
                  <div className="w-full h-96 p-10 bg-white rounded-xl shadow-lg">
                    <DropDownList listOfOptions={carrierOptions} handleChange={handleCarrierDropDownChange}/>
                    <Bar data={barData} options={barOptions} />
                  </div>
                </div>
              )}

              <div className="flex-auto p-2 max-w-full h-96 bg-white rounded-xl shadow-lg">
                  <Line data={avgWeekdayDeliveryDaysData} options={avgWeekDayDeliveryDayslineOptions} />
                </div>

                              <div className="grid grid-cols-2 space-x-8 ml-8 mt-8 mr-8">
                {!!totalSpendSurchargeData && (
                  <div className="flex-auto p-8 max-w-full h-96 bg-white rounded-xl shadow-lg">
                    <DropDownList listOfOptions={totalCarrierOptions} handleChange={handleTotalCarrierDropDownChange}/>
                    <Line data={totalSpendSurchargeData} options={totalSpendSurchargelineOptions} />
                  </div>
                )}
                
                
              </div>

              <div className="grid grid-cols-2 space-x-8 ml-8 mt-8 mr-8">
                <div className="flex-auto p-2 max-w-full h-96 bg-white rounded-xl shadow-lg">
                  <Doughnut data={doughnutData} options={doughnutOptions} />
                </div>
                <div className="flex-auto p-2 max-w-full h-96 bg-white rounded-xl shadow-lg">
                  <Bar data={monthlyShipmentBarData} options={monthlyShipmentBarOptions} />
                </div>
              </div>

              <div className="grid grid-cols-2 space-x-8 ml-8 mt-8 mr-8">
                {!!totalSpendSurchargeData && (
                  <div className="flex-auto p-8 max-w-full h-96 bg-white rounded-xl shadow-lg">
                    <DropDownList listOfOptions={totalCarrierOptions} handleChange={handleTotalCarrierDropDownChange}/>
                    <Line data={totalSpendSurchargeData} options={totalSpendSurchargelineOptions} />
                  </div>
                )}
                
                <div className="flex-auto p-2 max-w-full h-96 bg-white rounded-xl shadow-lg">
                  <Line data={avgWeekdayDeliveryDaysData} options={avgWeekDayDeliveryDayslineOptions} />
                </div>
              </div>

                            <div className="grid grid-cols-1 h-auto space-x-8 ml-8 mt-8 mr-8">
                <div className="w-full h-96 p-10 bg-white rounded-xl shadow-lg">
                  <Bar data={avgShipDateDeltaData} options={avgShipDateDeltaOptions} />
                </div>
              </div>

  
              <div className="grid grid-cols-1 h-auto space-x-8 ml-8 mt-8 mr-8">
                <div className="w-full h-96 p-10 bg-white rounded-xl shadow-lg">
                  <Bar data={avgShipDateDeltaData} options={avgShipDateDeltaOptions} />
                </div>
              </div>
            </div>
          </main>
        </div>
        */
    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="grid grid-cols-2 space-x-4 ml-8 mt-8 mr-8" />
                    </div>
                </main>
            </div>
        </div>
    );
};

//

export default Dashboard;
