import { getRequestHeader } from '../../utils/auth';
import useFetch from '../useFetch';
import { PricingMethodCol, PricingMethodMarginCol, PricingMethodVariableCol, TierConditionCol, backendUri } from '../../consts/vars';
import { getDropdownOptions } from '../../consts/func';
import { transformRuleConditionOptions } from '../../utils/invoice';

const useInvoiceApi = () => {
    const { makeApiCall } = useFetch();

    const getInvoiceRuleConditionOptions = async () => {
        const request = {
            method: 'get',
            url: `${backendUri}/invoice/ui/rule-condition-options`,
            headers: getRequestHeader(),
        };

        const response = await makeApiCall(request);

        return transformRuleConditionOptions(response.data);
    };

    const getPricingRuleFormOptions = () => {
        const request = {
            method: 'get',
            url: `${backendUri}/invoice/ui/pricing-rule-form-options`,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const getInvoiceUiOptions = async () => {
        const data = await getPricingRuleFormOptions();

        return {
            tierConditionOptions: getDropdownOptions(data.data.tier_conditions, TierConditionCol),
            pricingMethodOptions: getDropdownOptions(data.data.pricing_methods, PricingMethodCol),
            pricingMethodMarginOptions: getDropdownOptions(data.data.pricing_method_margin, PricingMethodMarginCol),
            pricingMethodVariableOptions: getDropdownOptions(data.data.pricing_method_variable, PricingMethodVariableCol),
        };
    };

    const getInvoiceChargeTypes = (chargeLevel = null) => {
        const request = {
            method: 'get',
            url: `${backendUri}/invoice/charge_types${chargeLevel ? `?charge_level=${chargeLevel}` : ''}`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const createInvoiceChargeType = (formData) => {
        const request = {
            method: 'post',
            url: `${backendUri}/invoice/charge_types`,
            headers: getRequestHeader(),
            data: {
                name: formData.name,
                charge_on_invoice: formData.charge_on_invoice,
                description: formData.description,
                required: formData.required,
                charge_level: formData.charge_level.value,
            },
        };

        return makeApiCall(request);
    };

    const updateInvoiceChargeTypes = (formData, chargeTypeId) => {
        const request = {
            method: 'post',
            url: `${backendUri}/invoice/charge_types/${chargeTypeId}/edit`,
            headers: getRequestHeader(),
            data: {
                name: formData.name,
                charge_on_invoice: formData.charge_on_invoice,
                description: formData.description,
                required: formData.required,
                charge_level: formData.charge_level.value,
            },
        };

        return makeApiCall(request);
    };

    const deleteInvoiceChargeTypes = (chargeTypeId) => {
        const request = {
            method: 'delete',
            url: `${backendUri}/invoice/charge_types/${chargeTypeId}`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const getProducts = () => {
        const request = {
            method: 'get',
            url: `${backendUri}/invoice/products`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const updateProduct = async (productId, productData) => {
        const request = {
            method: 'put',
            url: `${backendUri}/invoice/products/${productId}`,
            headers: getRequestHeader(),
            data: productData,
        };
        const response = await makeApiCall(request);
        return response;
    };

    const postKit = async (kitData) => {
        const request = {
            method: 'post',
            url: `${backendUri}/invoice/kits`,
            headers: getRequestHeader(),
            data: kitData,
        };
        const response = await makeApiCall(request);
        return response;
    };

    const getPackages = () => {
        const request = {
            method: 'get',
            url: `${backendUri}/invoice/packages`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const createPackage = (packageData) => {
        const request = {
            method: 'post',
            url: `${backendUri}/invoice/packages`,
            headers: getRequestHeader(),
            data: {
                height: Number(packageData.height),
                length: Number(packageData.length),
                name: packageData.name,
                width: Number(packageData.width),
                charge: Number(packageData.charge),
                reference_id: packageData.reference_id,
            },
        };
        return makeApiCall(request);
    };

    const updatePackage = async (packageId, packageData) => {
        const request = {
            method: 'put',
            url: `${backendUri}/invoice/packages/${packageId}`,
            headers: getRequestHeader(),
            data: packageData,
        };
        const response = await makeApiCall(request);
        return response;
    };

    const deletePackage = (packageId) => {
        const request = {
            method: 'delete',
            url: `${backendUri}/invoice/packages/${packageId}`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const getInvoiceCustomer = (customerId) => {
        const request = {
            method: 'get',
            url: `${backendUri}/invoice/customers/${customerId}`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const getInvoiceCustomers = () => {
        const request = {
            method: 'get',
            url: `${backendUri}/invoice/customers`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const createInvoiceCustomer = (formData) => {
        const request = {
            method: 'post',
            url: `${backendUri}/invoice/customers`,
            headers: getRequestHeader(),
            data: formData,
        };
        return makeApiCall(request);
    };

    const updateInvoiceCustomer = (customerId, formData) => {
        const request = {
            method: 'put',
            url: `${backendUri}/invoice/customers/${customerId}`,
            headers: getRequestHeader(),
            data: formData,
        };
        return makeApiCall(request);
    };

    const updateInvoiceCustomerActiveStatus = (customerId, isActive) => {
        const request = {
            method: 'put',
            url: `${backendUri}/invoice/customers/${customerId}/active?active=${isActive}`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const getCustomerChargeDashboard = (filter = null) => {
        const request = {
            method: 'get',
            url: `${backendUri}/general/views/invoice.vw_customer_charge_dashboard${filter ? `?filter=${filter}` : ''}`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const getActivePricingRules = async (customerId = null) => {
        const request = {
            method: 'get',
            url: `${backendUri}/invoice/rules${customerId ? `?customer_id=${customerId}` : ''}`,
            headers: getRequestHeader(),
        };

        const res = await makeApiCall(request);

        return res.data.filter((rule) => rule.charge_type !== 'package_rate' && !rule.name.includes('attributing missing'));
    };

    const getRule = (ruleId) => {
        const request = {
            method: 'get',
            url: `${backendUri}/invoice/rules/${ruleId}`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const cleanRule = (rule) => {
        const finalRule = { ...rule };

        if (rule.default === false && rule.customer_ids.length > 0) {
            finalRule.customer_ids = rule.customer_ids.map((customer) => customer.value);
        } else {
            finalRule.customer_ids = [];
        }

        finalRule.charge_type_id = rule.charge_type.value;

        finalRule.rule_conditions = rule.rule_conditions.map((condition) => {
            let condValue;

            if (typeof condition.value === 'string') {
                condValue = condition.value;
            } else if (condition.value.constructor.name === 'Array') {
                condValue = condition.value.map((val) => val.value).join(',');
            } else if (condition.value.constructor.name === 'Object') {
                condValue = condition.value.value;
            }

            return {
                col: condition.col.value,
                filter: condition.filter.value,
                value: condValue,
            };
        });

        finalRule.tiers = rule.tiers.map((tier) => {
            const finalTier = { ...tier };

            finalTier.col = tier.col ? tier.col : null;
            finalTier.method = tier.method;
            finalTier.value = Number(tier.value);
            finalTier.tier_conditions = [];

            if (tier.tier_conditions.rangeCol && tier.tier_conditions.rangeStart) {
                finalTier.tier_conditions.push({
                    col: tier.tier_conditions.rangeCol,
                    filter: '>=',
                    value: tier.tier_conditions.rangeStart,
                });

                if (tier.tier_conditions.rangeEnd) {
                    finalTier.tier_conditions.push({
                        col: tier.tier_conditions.rangeCol,
                        filter: '<=',
                        value: tier.tier_conditions.rangeEnd,
                    });
                }
            }

            return finalTier;
        });

        return {
            charge_type_id: finalRule.charge_type_id,
            customer_ids: finalRule.customer_ids,
            default: finalRule.default,
            name: finalRule.name,
            rule_conditions: finalRule.rule_conditions,
            tiers: finalRule.tiers,
            tiers_are_cumulative: finalRule.tiers_are_cumulative,
        };
    };

    const createRule = (rule) => {
        const request = {
            method: 'post',
            url: `${backendUri}/invoice/rules`,
            headers: getRequestHeader(),
            data: cleanRule(rule),
        };

        return makeApiCall(request);
    };

    const updateRule = (ruleId, rule) => {
        const request = {
            method: 'put',
            url: `${backendUri}/invoice/rules/${ruleId}/edit`,
            headers: getRequestHeader(),
            data: cleanRule(rule),
        };

        return makeApiCall(request);
    };

    const updateRuleActiveStatus = (ruleId, isActive) => {
        const request = {
            method: 'put',
            url: `${backendUri}/invoice/rules/${ruleId}/active?active=${isActive}`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const deleteRule = (ruleId) => {
        const request = {
            method: 'delete',
            url: `${backendUri}/invoice/rules/${ruleId}`,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const getInvoiceDetailFile = (invoiceId) => {
        const request = {
            method: 'get',
            url: `${backendUri}/invoice/invoices-delivery-file/${invoiceId}`,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const getAllConfigs = (isDefault = null) => {
        const request = {
            method: 'get',
            url: `${backendUri}/config${isDefault ? `?default=${isDefault}` : ''}`,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const getInvoiceConfig = () => {
        const request = {
            method: 'get',
            url: `${backendUri}/config/invoice/`,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const putInvoiceConfig = (body) => {
        const request = {
            method: 'put',
            url: `${backendUri}/config/invoice/?id=${body.id}`,
            data: body,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const putInvoiceChargeConfig = (body) => {
        const request = {
            method: 'put',
            url: `${backendUri}/config/charge-types/invoice-level/?id=${body.id}`,
            data: body,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const putPackageChargeConfig = (body) => {
        const request = {
            method: 'put',
            url: `${backendUri}/config/charge-types/package-level/?id=${body.id}`,
            data: body,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const deleteInvoiceConfig = (body) => {
        const request = {
            method: 'delete',
            url: `${backendUri}/config/invoice/?id=${body.id}`,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const deleteInvoiceChargeConfig = (body) => {
        const request = {
            method: 'delete',
            url: `${backendUri}/config/charge-types/invoice-level/?id=${body.id}`,
            data: body,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const deletePackageChargeConfig = (body) => {
        const request = {
            method: 'delete',
            url: `${backendUri}/config/charge-types/package-level/?id=${body.id}`,
            data: body,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const createInvoiceConfig = (body) => {
        const request = {
            method: 'post',
            url: `${backendUri}/config/invoice/`,
            data: body,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const createInvoiceChargeConfig = (body) => {
        const request = {
            method: 'post',
            url: `${backendUri}/config/charge-types/invoice-level/`,
            data: body,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const createPackageChargeConfig = (body) => {
        const request = {
            method: 'post',
            url: `${backendUri}/config/charge-types/package-level/`,
            data: body,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const createLineItemForShipment = (amount, chargeType, shipmentIds) => {
        const request = {
            method: 'post',
            url: `${backendUri}/invoice/lineitems/`,
            headers: getRequestHeader(),
            data: {
                amount: Number(amount),
                charge_type_id: chargeType,
                shipment_ids: shipmentIds,
            },
        };

        return makeApiCall(request);
    };

    const createLineItemForInvoice = (formData) => {
        const payload = {
            amount: formData.amount,
            charge_type_id: formData.charge_type_id,
            invoice_id: formData.invoice_id,
            description: formData.description,
            quantity: formData.quantity,
        };

        if (formData.order_id) {
            payload.order_id = formData.order_id;
        }

        if (formData.package_id) {
            payload.package_id = formData.package_id;
        }

        const request = {
            method: 'post',
            url: `${backendUri}/invoice/lineitems`,
            headers: getRequestHeader(),
            data: payload,
        };

        return makeApiCall(request);
    };

    const getUserData = () => {
        const request = {
            method: 'get',
            url: `${backendUri}/invoice/user`,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const updateUserData = (timeZone) => {
        const request = {
            method: 'put',
            url: `${backendUri}/invoice/user?timezone=${timeZone}`,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const updateLineItemManually = (invoice, lineItem, data) => {
        const request = {
            method: 'put',
            url: `${backendUri}/invoice/lineitems/${invoice}/${lineItem}?amount=${data}`,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const createInvoice = (invoiceData) => {
        const request = {
            method: 'post',
            url: `${backendUri}/invoice/ad-hoc-invoices`,
            headers: getRequestHeader(),
            data: {
                customer_id: invoiceData.value,
            },
        };

        return makeApiCall(request);
    };

    const uploadToQuickBooks = (invoiceId, fileUrl) => {
        const request = {
            method: 'post',
            url: `${backendUri}/invoice/invoices/quickbooks`,
            headers: getRequestHeader(),
            data: {
                invoice_id: invoiceId,
                file_url: fileUrl,
            },
        };

        return makeApiCall(request);
    };

    const getInvoiceSummaryByCompany = () => {
        const request = {
            method: 'get',
            url: `${backendUri}/invoice/invoice-summaries`,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const getInvoiceSummaryByCustomer = (customerId) => {
        const request = {
            method: 'get',
            url: `${backendUri}/invoice/invoice-summaries?customerId=${customerId}`,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const getInvoiceSummaryByInvoice = (invoiceId) => {
        const request = {
            method: 'get',
            url: `${backendUri}/invoice/invoice-summaries/${invoiceId}`,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    return {
        getInvoiceRuleConditionOptions,
        getInvoiceUiOptions,
        getInvoiceChargeTypes,
        getInvoiceCustomer,
        getInvoiceCustomers,
        createInvoiceCustomer,
        updateInvoiceCustomer,
        updateInvoiceCustomerActiveStatus,
        getCustomerChargeDashboard,
        getActivePricingRules,
        getRule,
        createRule,
        updateRule,
        updateRuleActiveStatus,
        deleteRule,
        getInvoiceDetailFile,
        getProducts,
        updateProduct,
        postKit,
        getPackages,
        createPackage,
        updatePackage,
        deletePackage,
        getAllConfigs,
        getInvoiceConfig,
        putInvoiceConfig,
        putInvoiceChargeConfig,
        putPackageChargeConfig,
        deleteInvoiceConfig,
        deleteInvoiceChargeConfig,
        deletePackageChargeConfig,
        createInvoiceConfig,
        createInvoiceChargeConfig,
        createPackageChargeConfig,
        createLineItemForShipment,
        createLineItemForInvoice,
        backendUri,
        deleteInvoiceChargeTypes,
        createInvoiceChargeType,
        updateInvoiceChargeTypes,
        getUserData,
        updateUserData,
        updateLineItemManually,
        createInvoice,
        uploadToQuickBooks,
        getInvoiceSummaryByCompany,
        getInvoiceSummaryByCustomer,
        getInvoiceSummaryByInvoice,
    };
};

export default useInvoiceApi;
