import { useState, useEffect } from 'react';
import {
    statsCarrierBillsStatsGet,
    postConfigCarrierReportExportPost,
    getConfigCarrierReportExportGet,
    tableDataDownloadCarrierBillsReportsChargeDetailJsonGet,
    postConfigCarrierReportExportEmailPost,
    getConfigCarrierReportExportEmailGet,
} from '@/client/services.gen';
import { type StatsCarrierBillsStatsGetData, type StatsCarrierBillsStatsGetResponse, Aggregation, Period, ColSelect, ColAggBy } from '@/client/types.gen';
import { wrapperFunction } from '@/client/wrapperFunction';
import { Bar, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Cog8ToothIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Select, SelectItem, SelectContent, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { format, subWeeks } from 'date-fns';
import { Input } from '@/components/ui/input';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';

const ftpFormSchema = z.object({
    host: z
        .string()
        .min(1, 'Host is required')
        .refine((value) => /^(\d{1,3}\.){3}\d{1,3}$|^[a-zA-Z0-9.-]+$/.test(value), {
            message: 'Enter a valid IP address or hostname',
        }),
    port: z.number().int().min(1).max(65535),
    username: z.string().min(1, 'Username is required'),
    password: z.string().min(1, 'Password is required'),
    directory: z
        .string()
        .min(1, 'Directory is required')
        .refine((value) => /^(\/[^/ ]*)+\/?$/.test(value), {
            message: 'Please enter a valid Unix-style directory path',
        }),
});

type FtpFormValues = z.infer<typeof ftpFormSchema>;

export default function CarrierBillReports() {
    const [colAggBy, setColAggBy] = useState<StatsCarrierBillsStatsGetData['colAggBy']>('CARRIER_NAME');
    const [colSelect, setColSelect] = useState<StatsCarrierBillsStatsGetData['colSelect']>('CHARGE_AMOUNT');
    const [typeAgg, setTypeAgg] = useState<StatsCarrierBillsStatsGetData['typeAgg']>('sum');
    const [typeDate, setTypeDate] = useState<StatsCarrierBillsStatsGetData['typeDate']>('week');
    const [startDate, setStartDate] = useState<Date>(subWeeks(new Date(), 6));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [stats, setStats] = useState<StatsCarrierBillsStatsGetResponse | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [downloadStartDate, setDownloadStartDate] = useState<Date>(subWeeks(new Date(), 1));
    const [downloadEndDate, setDownloadEndDate] = useState<Date>(new Date());
    const [emailId, setEmailId] = useState<string>('');

    const form = useForm<FtpFormValues>({
        resolver: zodResolver(ftpFormSchema),
        defaultValues: {
            host: '',
            port: 22,
            username: '',
            password: '',
            directory: '',
        },
    });

    const handleFetchStats = async () => {
        setIsLoading(true);
        setError(null);
        try {
            // @ts-ignore
            const response = await wrapperFunction(statsCarrierBillsStatsGet, {
                colAggBy,
                colSelect,
                typeAgg,
                typeDate,
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
            });
            setStats(response);
        } catch (err) {
            setError('Failed to fetch stats. Please try again.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchFtpConfigs = async () => {
        try {
            // @ts-ignore
            const response = await wrapperFunction(getConfigCarrierReportExportGet, {});
        } catch (error) {
            console.error('Error fetching FTP configurations:', error);
            setError('Failed to fetch FTP configurations. Please try again.');
        }
    };

    const fetchEmailConfig = async () => {
        try {
            const response = await wrapperFunction(getConfigCarrierReportExportEmailGet, {});
            if (response && response.email) {
                setEmailId(response.email);
            }
        } catch (error) {
            console.error('Error fetching email configuration:', error);
            setError('Failed to fetch email configuration. Please try again.');
        }
    };

    useEffect(() => {
        handleFetchStats();
        fetchEmailConfig();
    }, []);

    const formatChartData = (data: StatsCarrierBillsStatsGetResponse) => {
        const dataMap: { [key: string]: any } = {};
        data.forEach((item) => {
            const date = new Date(item.timestampz).toISOString().split('T')[0]; // Convert to YYYY-MM-DD
            // TODO: we should infer this from the data. it's whatever key is not 'value' or 'timestampz'
            const aggKey = item['carrier' as keyof typeof item];
            const value = parseFloat(item.value);

            if (!dataMap[date]) {
                dataMap[date] = { date };
            }

            if (typeAgg === 'count_distinct') {
                dataMap[date][aggKey] = (dataMap[date][aggKey] || 0) + 1;
            } else {
                dataMap[date][aggKey] = (dataMap[date][aggKey] || 0) + value;
            }
        });
        return Object.values(dataMap);
    };

    const getDistinctDataKeys = (data: StatsCarrierBillsStatsGetResponse | null) => {
        if (!data) return [];
        const key = 'carrier' as keyof StatsCarrierBillsStatsGetResponse[number];
        return Array.from(new Set(data.map((item) => item[key])));
    };

    const onSubmit = async (values: FtpFormValues) => {
        setIsSubmitting(true);
        setSuccessMessage(null);
        setError(null);
        try {
            const requestBody = {
                host: values.host,
                port: values.port,
                username: values.username,
                password: values.password,
                dir: values.directory,
            };
            await wrapperFunction(postConfigCarrierReportExportPost, {
                requestBody,
            });
            setSuccessMessage('FTP configuration has been saved successfully.');
            await fetchFtpConfigs();
        } catch (error) {
            setError('Failed to save FTP configuration. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleEmailSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSuccessMessage(null);
        setError(null);
        try {
            await wrapperFunction(postConfigCarrierReportExportEmailPost, {
                requestBody: { email: emailId },
            });
            setSuccessMessage('Email configuration has been saved successfully.');
            await fetchEmailConfig();
        } catch (error) {
            setError('Failed to save email configuration. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDownload = async () => {
        setIsLoading(true);
        setError(null);
        try {
            // @ts-ignore
            const response = await wrapperFunction(tableDataDownloadCarrierBillsReportsChargeDetailJsonGet, {
                startDate: downloadStartDate.toISOString(),
                endDate: downloadEndDate.toISOString(),
            });

            // Create a Blob from the response
            const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });

            // Create a link element and trigger the download
            // @ts-ignore
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', 'carrier_bill_report.csv');
                link.style.visibility = 'hidden';
                // @ts-ignore
                document.body.appendChild(link);
                link.click();
                // @ts-ignore
                document.body.removeChild(link);

                setSuccessMessage('Download successful.');
            }
        } catch (err) {
            setError('Failed to download data. Please try again.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="p-4">
            <div className="flex justify-end">
                <Dialog>
                    <DialogTrigger asChild>
                        <Button>
                            <div className="pr-2">
                                <ArrowDownTrayIcon className="w-4 h-4" />
                            </div>
                            <div>Export</div>
                        </Button>
                    </DialogTrigger>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Export Reports</DialogTitle>
                        </DialogHeader>
                        <Tabs defaultValue="download">
                            <TabsList className="grid w-full grid-cols-3">
                                <TabsTrigger value="download" className="w-full">
                                    Download
                                </TabsTrigger>
                                <TabsTrigger value="email" className="w-full">
                                    Schedule Email
                                </TabsTrigger>
                                <TabsTrigger value="ftp" className="w-full">
                                    SFTP Dump
                                </TabsTrigger>
                            </TabsList>
                            <TabsContent value="download">
                                <form className="space-y-4">
                                    <div className="flex space-x-4">
                                        <div className="space-y-2">
                                            <label className="block text-sm font-medium text-gray-700">Start Date</label>
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Button variant="outline">{format(downloadStartDate, 'PP')}</Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0">
                                                    <Calendar mode="single" selected={downloadStartDate} onSelect={(date) => date && setDownloadStartDate(date)} initialFocus />
                                                </PopoverContent>
                                            </Popover>
                                        </div>
                                        <div className="space-y-2">
                                            <label className="block text-sm font-medium text-gray-700">End Date</label>
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Button variant="outline">{format(downloadEndDate, 'PP')}</Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0">
                                                    <Calendar mode="single" selected={downloadEndDate} onSelect={(date) => date && setDownloadEndDate(date)} initialFocus />
                                                </PopoverContent>
                                            </Popover>
                                        </div>
                                    </div>
                                    <Button onClick={handleDownload} disabled={isLoading}>
                                        {isLoading ? 'Downloading...' : 'Download'}
                                    </Button>
                                    {successMessage && <p className="text-green-500">{successMessage}</p>}
                                    {error && <p className="text-red-500">{error}</p>}
                                </form>
                            </TabsContent>
                            <TabsContent value="email">
                                <form onSubmit={handleEmailSubmit} className="space-y-4">
                                    <div className="space-y-2">
                                        <label htmlFor="emailId" className="block text-sm font-medium text-gray-700">
                                            Email ID
                                        </label>
                                        <Input id="emailId" type="email" placeholder="Enter your email" value={emailId} onChange={(e) => setEmailId(e.target.value)} />
                                    </div>
                                    <Button type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? 'Submitting...' : 'Schedule Email'}
                                    </Button>
                                    {successMessage && <p className="text-green-500">{successMessage}</p>}
                                    {error && <p className="text-red-500">{error}</p>}
                                </form>
                            </TabsContent>
                            <TabsContent value="ftp">
                                <Form {...form}>
                                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                                        <FormField
                                            control={form.control}
                                            name="host"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Host</FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="Enter FTP host" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="port"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Port</FormLabel>
                                                    <FormControl>
                                                        {/* @ts-ignore */}
                                                        <Input
                                                            type="number"
                                                            placeholder="Enter FTP port"
                                                            {...field}
                                                            onChange={(e) => field.onChange(parseInt(e.target.value, 10))}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="username"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Username</FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="Enter FTP username" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="password"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Password</FormLabel>
                                                    <FormControl>
                                                        <Input type="password" placeholder="Enter FTP password" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="directory"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Directory</FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="Enter FTP directory" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <Button type="submit" disabled={isSubmitting}>
                                            {isSubmitting ? <div className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent"></div> : null}
                                            {isSubmitting ? 'Submitting...' : 'Set up FTP Export'}
                                        </Button>
                                        {successMessage && <p className="text-green-500">{successMessage}</p>}
                                        {error && <p className="text-red-500">{error}</p>}
                                    </form>
                                </Form>
                            </TabsContent>
                        </Tabs>
                    </DialogContent>
                </Dialog>
            </div>
            <Card className="w-full mt-2 overflow-auto">
                <CardHeader className="relative">
                    <CardTitle>Carrier Spend and Utilization</CardTitle>
                    <CardDescription>Analyze carrier costs and usage</CardDescription>
                    <div className="absolute top-2 right-2">
                        <Popover open={isSettingsOpen} onOpenChange={setIsSettingsOpen}>
                            <PopoverTrigger asChild>
                                <Button variant="ghost" size="icon">
                                    <Cog8ToothIcon className="w-6 h-6" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-80" align="end" side="bottom" sideOffset={8}>
                                <div className="space-y-4">
                                    <div className="space-y-2">
                                        <label htmlFor="colAggBy" className="block text-sm font-medium text-gray-700">
                                            Aggregate By
                                        </label>
                                        <Select value={colAggBy} onValueChange={(value) => setColAggBy(value as StatsCarrierBillsStatsGetData['colAggBy'])}>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select aggregate by" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {Object.entries(ColAggBy).map(([key, value]) => (
                                                    <SelectItem key={key} value={value}>
                                                        {value.replace(/\./g, ' ').replace(/_/g, ' ')}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>

                                    <div className="space-y-2">
                                        <label htmlFor="colSelect" className="block text-sm font-medium text-gray-700">
                                            Select Column
                                        </label>
                                        <Select value={colSelect} onValueChange={(value) => setColSelect(value as StatsCarrierBillsStatsGetData['colSelect'])}>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select column" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {Object.entries(ColSelect).map(([key, value]) => (
                                                    <SelectItem key={key} value={value}>
                                                        {value}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>

                                    <div className="space-y-2">
                                        <label htmlFor="typeAgg" className="block text-sm font-medium text-gray-700">
                                            Aggregation Type
                                        </label>
                                        <Select value={typeAgg} onValueChange={(value) => setTypeAgg(value as StatsCarrierBillsStatsGetData['typeAgg'])}>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select aggregation type" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {Object.entries(Aggregation).map(([key, value]) => (
                                                    <SelectItem key={key} value={value}>
                                                        {value}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>

                                    <div className="space-y-2">
                                        <label htmlFor="typeDate" className="block text-sm font-medium text-gray-700">
                                            Date Type
                                        </label>
                                        <Select value={typeDate} onValueChange={(value) => setTypeDate(value as StatsCarrierBillsStatsGetData['typeDate'])}>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select date type" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {Object.entries(Period).map(([key, value]) => (
                                                    <SelectItem key={key} value={value}>
                                                        {value}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>

                                    <div className="space-y-2">
                                        <label className="block text-sm font-medium text-gray-700">Date Range</label>
                                        <div className="flex space-x-2">
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Button variant="outline">{format(startDate, 'PP')}</Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0">
                                                    <Calendar mode="single" selected={startDate} onSelect={(date) => date && setStartDate(date)} initialFocus />
                                                </PopoverContent>
                                            </Popover>
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Button variant="outline">{format(endDate, 'PP')}</Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0">
                                                    <Calendar mode="single" selected={endDate} onSelect={(date) => date && setEndDate(date)} initialFocus />
                                                </PopoverContent>
                                            </Popover>
                                        </div>
                                    </div>

                                    <Button
                                        onClick={() => {
                                            handleFetchStats();
                                            setIsSettingsOpen(false);
                                        }}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Loading...' : 'Fetch Data'}
                                    </Button>
                                </div>
                            </PopoverContent>
                        </Popover>
                    </div>
                </CardHeader>
                <CardContent>
                    {error && <p className="text-red-500">{error}</p>}

                    {stats && (
                        <div className="mt-4">
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart data={formatChartData(stats)} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis width={80} />
                                    <Tooltip />
                                    <Legend />
                                    {getDistinctDataKeys(stats).map((key, index) => (
                                        <Bar key={key} dataKey={key} fill={`hsl(var(--chart-${index + 1}))`} name={key} />
                                    ))}
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    )}
                </CardContent>
            </Card>
        </div>
    );
}
