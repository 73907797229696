/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react';

import { DataTable } from '../../common/datatable/dataTable.tsx';
import { DataTableColumnHeader } from '../../common/datatable/dataTableColumnHeader.tsx';

import StorageTypeCreateRate from './storageTypesCreate';
import StorageTypeView from './storageTypesView';
import { getInvoiceRuleDisplayName } from '../../../utils/invoice';
import useStorageApi from '../../../hooks/api/useStorageApi';
import { showUnits } from '../../invoices/products/productView';

const StorageTypes = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [selectedStorageType, setSelectedStorageType] = useState(null);
    const [open, setOpen] = useState(false);
    const { getStorageTypes } = useStorageApi();

    const fetchStorageTypes = async () => {
        setIsLoading(true);
        getStorageTypes().then((res) => {
            setTableData(res.data);
            setIsLoading(false);
        });
    };

    const openStorageTypeView = (row) => {
        setSelectedStorageType(tableData.filter((data) => data.id === row.original.id)[0]);
        setOpen(true);
    };

    useEffect(() => {
        fetchStorageTypes();
    }, []);

    const columns = [
        {
            accessorKey: 'name',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
            cell: ({ row }) => (
                <div className="w-[200px] font-medium hover:underline" onClick={() => openStorageTypeView(row)}>
                    <a href="#">{getInvoiceRuleDisplayName(row.getValue('name'))}</a>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'dimensions',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Dimensions" />,
            cell: ({ row }) => (
                <div className="w-[100px] font-medium">
                    {row.getValue('dimensions') && (
                        <>
                            <span className="font-bold">L: </span>
                            {row.getValue('dimensions')?.length ?? '-'} {showUnits(row.getValue('dimensions')?.length_unit)}
                            <span className="font-bold"> H: </span>
                            {row.getValue('dimensions')?.height ?? '-'} {showUnits(row.getValue('dimensions')?.length_unit)}
                            <span className="font-bold"> W: </span>
                            {row.getValue('dimensions')?.width ?? '-'} {showUnits(row.getValue('dimensions')?.length_unit)}
                        </>
                    )}
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        // {
        //     accessorKey: 'length',
        //     header: ({ column }) => <DataTableColumnHeader column={column} title="Length" />,
        //     cell: ({ row }) => (
        //         <div className="flex space-x-2">
        //             <span className="max-w-[500px] text-wrap">{row.getValue('length')}</span>
        //         </div>
        //     ),
        //     enableSorting: true,
        //     enableHiding: false,
        // },
        // {
        //     accessorKey: 'width',
        //     header: ({ column }) => <DataTableColumnHeader column={column} title="Width" />,
        //     cell: ({ row }) => (
        //         <div className="flex space-x-2">
        //             <span className="max-w-[500px] text-wrap">{row.getValue('width')}</span>
        //         </div>
        //     ),
        //     enableSorting: true,
        //     enableHiding: false,
        // },
        // {
        //     accessorKey: 'height',
        //     header: ({ column }) => <DataTableColumnHeader column={column} title="Height" />,
        //     cell: ({ row }) => (
        //         <div className="flex space-x-2">
        //             <span className="max-w-[500px] text-wrap">{row.getValue('height')}</span>
        //         </div>s
        //     ),
        //     enableSorting: true,
        //     enableHiding: false,
        // },
    ];

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">Storage Types</h1>
                            <h2 className="text-xs text-rails-dark-blue">Manage storage types.</h2>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="container mx-auto py-20">
                                {tableData && (
                                    <DataTable
                                        loading={isLoading}
                                        data={tableData}
                                        columns={columns}
                                        ActionButton={<StorageTypeCreateRate fetchStorageTypes={fetchStorageTypes} />}
                                        isMultiSelectRows={false}
                                        isDownloadable
                                        downloadData={tableData}
                                        tableName="storageTypes"
                                        showActionButtonInToolbar
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            <StorageTypeView open={open} setOpen={setOpen} selectedStorageType={selectedStorageType} />
        </div>
    );
};

export default StorageTypes;
