import { backendUri } from '../../consts/vars';
import { getRequestHeader } from '../../utils/auth';
import useFetch from '../useFetch';

const useGeneralApi = () => {
    const { makeApiCall } = useFetch();

    const baseUri = `${backendUri}/general`;

    const getViewData = (name, limit, filter = null, columns = null) => {
        const urlToUse = new URL(`${baseUri}/views/${name}`);
        if (limit) {
            urlToUse.searchParams.append('limit', limit);
        }
        if (filter) {
            urlToUse.searchParams.append('filter', filter);
        }

        if (columns) {
            urlToUse.searchParams.append('columns', columns);
        }

        const request = {
            method: 'get',
            url: urlToUse,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    const getGenericViewData = (name, limit, filter = null) => {
        const urlToUse = new URL(`${baseUri}/generic-views/${name}`);
        if (limit) {
            urlToUse.searchParams.append('limit', limit);
        }
        if (filter) {
            urlToUse.searchParams.append('filter', filter);
        }

        const request = {
            method: 'get',
            url: urlToUse,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    return { getViewData, getGenericViewData };
};

export default useGeneralApi;
