/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react';

import { DataTable } from '../../common/datatable/dataTable.tsx';
import { DataTableColumnHeader } from '../../common/datatable/dataTableColumnHeader.tsx';

import ReceivingFeeCreate from './receivingFeesCreate';
import ReceivingFeeView from './receivingFeesView';
import useReceivingApi from '../../../hooks/api/useReceivingApi';
import useInvoiceApi from '../../../hooks/api/useInvoiceApi';
import { ReceivingChargeType } from './forms/receivingFeesForm';

const ReceivingFees = ({ showCreate = true }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [selectedReceivingFee, setSelectedReceivingFee] = useState(null);
    const [open, setOpen] = useState(false);

    const { getReceivingFee } = useReceivingApi();
    const { getInvoiceCustomers } = useInvoiceApi();

    const fetchReceivingFees = async () => {
        getReceivingFee().then((res) => {
            setTableData(res.data);
        });
    };

    const fetchCustomers = async () => {
        getInvoiceCustomers().then((res) => {
            const filteredData = res.data
                .filter((customer) => customer.active === true)
                .filter((customer) => customer.invoice_customer_id === null)
                .map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
            setCustomerData(filteredData);
        });
    };

    const openReceivingFeesView = (row) => {
        setSelectedReceivingFee(tableData.filter((data) => data.id === row.original.id)[0]);
        setOpen(true);
    };

    useEffect(() => {
        setIsLoading(true);
        fetchReceivingFees();
        fetchCustomers();
        setIsLoading(false);
    }, []);

    const columns = [
        {
            accessorKey: 'name',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
            cell: ({ row }) => (
                <div className="w-[200px] font-medium hover:underline" onClick={() => openReceivingFeesView(row)}>
                    <a href="#">{row.getValue('name')}</a>
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_type',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Charge Type" />,
            cell: ({ row }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">{ReceivingChargeType.find((type) => type.value === row.getValue('charge_type'))?.label}</span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_amount',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Charge" />,
            cell: ({ row }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">{parseFloat(row.getValue('charge_amount'))?.toFixed(3)}</span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        // {
        //     accessorKey: 'active',
        //     header: ({ column }) => <DataTableColumnHeader column={column} title="Active" />,
        //     cell: ({ row }) => (
        //         <div className="z-99 w-[80px]">
        //             <Switch
        //                 checked={row.getValue('active')}
        //                 onCheckedChange={() => {
        //                     updateRuleActiveStatus(row.original.id, !row.getValue('active')).then(() => {
        //                         getActivePricingRules().then((res) => {
        //                             setTableData(res.data);
        //                         });
        //                     });
        //                 }}
        //             />
        //         </div>
        //     ),
        //     enableSorting: false,
        //     enableHiding: false,
        // },
    ];

    const DataTableComponent = () => (
        <DataTable
            loading={isLoading}
            data={tableData}
            columns={columns}
            ActionButton={showCreate ? <ReceivingFeeCreate customerData={customerData} /> : null}
            isMultiSelectRows={false}
        />
    );

    const ReceivingFeeViewComponent = () => <ReceivingFeeView open={open} setOpen={setOpen} selectedReceivingFee={selectedReceivingFee} customerData={customerData} />;

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">Receiving Fees</h1>
                            <h2 className="text-xs text-rails-dark-blue">Manage Receiving fees.</h2>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="container mx-auto py-20">{tableData && <DataTableComponent />}</div>
                        </div>
                    </div>
                </main>
            </div>

            <ReceivingFeeViewComponent />
        </div>
    );
};

export default ReceivingFees;
