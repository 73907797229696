import { backendUri } from '../../consts/vars';
import { getRequestHeader } from '../../utils/auth';
import useFetch from '../useFetch';

const useTableauApi = () => {
    const { makeApiCall } = useFetch();

    const baseUri = `${backendUri}/internal`;

    const getAuthData = () => {
        const urlToUse = new URL(`${baseUri}/tableau-auth`);

        const request = {
            method: 'get',
            url: urlToUse,
            headers: getRequestHeader(),
        };

        return makeApiCall(request);
    };

    return { getAuthData };
};

export default useTableauApi;
