/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react';

import { toast } from '../../common/use-toast.tsx';
import { ToastAction } from '../../common/toast.tsx';
import { DataTable } from '../../common/datatable/dataTable.tsx';
import { DataTableColumnHeader } from '../../common/datatable/dataTableColumnHeader.tsx';
import ProductView, { showUnits } from './productView';
import { Switch } from '../../common/switch.tsx';

import { getPaginatedInvoiceProductsGet, searchForProductInvoiceProductsSearchGet } from '../../../client/services.gen.ts';
import { wrapperFunction } from '../../../client/wrapperFunction.ts';

import ProductSearch from './productSearch';
import { Button } from '@/components/ui/button';

const Products = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [open, setOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);

    const fetchProducts = async () => {
        setIsLoading(true);
        await wrapperFunction(getPaginatedInvoiceProductsGet, { limit: 1000 }).then((res) => {
            if (res.total === 0) {
                toast({
                    variant: 'destructive',
                    title: 'Warning',
                    description: 'Currently no products in the database. \n Please add products',
                    action: <ToastAction altText=" I Understand">I Understand</ToastAction>,
                });
            } else {
                setTableData(res.items);
                if (res.total > 25000) {
                    toast({
                        variant: 'destructive',
                        title: 'Warning',
                        description: 'Too many products, only displaying the first 25k products. \n Please refine your search',
                        action: <ToastAction altText=" I Understand">I Understand</ToastAction>,
                    });
                }
            }
            setIsLoading(false);
        });
    };

    const handleSearchQuery = async (searchQuery) => {
        setIsLoading(true);
        setSearchOpen(false);
        await wrapperFunction(searchForProductInvoiceProductsSearchGet, searchQuery).then((res) => {
            if (res.total === 0) {
                toast({
                    variant: 'destructive',
                    title: 'Warning',
                    description: 'No products found. \n Please change your search',
                    action: <ToastAction altText=" I Understand">I Understand</ToastAction>,
                });
            } else {
                setTableData(res.items);
                if (res.total > 25000) {
                    toast({
                        variant: 'destructive',
                        title: 'Warning',
                        description: 'Too many products, only displaying the first 25k products. \n Please refine your search',
                        action: <ToastAction altText=" I Understand">I Understand</ToastAction>,
                    });
                }
            }
            setIsLoading(false);
        });
    };

    const resetTable = async () => {
        setSearchOpen(false);
        await handleSearchQuery({});
    };

    const openProductView = (row) => {
        setSelectedProduct(tableData.filter((data) => data.id === row.original.id)[0]);
        setOpen(true);
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    const columns = [
        {
            accessorKey: 'name',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
            cell: ({ row }) => (
                <div className="w-[200px] font-medium hover:underline" onClick={() => openProductView(row)}>
                    <a href="#">{row.getValue('name')}</a>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'sku',
            header: ({ column }) => <DataTableColumnHeader column={column} title="SKU" />,
            cell: ({ row }) => <div className="w-[100px] font-medium">{row.getValue('sku')}</div>,
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'customer',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Customer" />,
            cell: ({ row }) => <div className="w-[100px] font-medium">{row.getValue('customer')?.name}</div>,
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'dimensions',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Dimensions" />,
            cell: ({ row }) => (
                <div className="w-[100px] font-medium">
                    {row.getValue('dimensions') && (
                        <>
                            <span className="font-bold">L: </span>
                            {row.getValue('dimensions')?.length ?? '-'} {showUnits(row.getValue('dimensions')?.length_unit)}
                            <span className="font-bold"> H: </span>
                            {row.getValue('dimensions')?.height ?? '-'} {showUnits(row.getValue('dimensions')?.length_unit)}
                            <span className="font-bold"> W: </span>
                            {row.getValue('dimensions')?.width ?? '-'} {showUnits(row.getValue('dimensions')?.length_unit)}
                            <span className="font-bold"> Wt: </span>
                            {row.getValue('dimensions')?.weight ?? '-'} {showUnits(row.getValue('dimensions')?.weight_unit)}
                        </>
                    )}
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'unit_charge',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Charge Rate" />,
            cell: ({ row }) => <div className="w-[100px] font-medium">{row.getValue('unit_charge') && <>${parseFloat(row.getValue('unit_charge'))?.toFixed(2)}</>}</div>,
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'is_kit',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Is a Kit" />,
            cell: ({ row }) => (
                <div className="z-99 w-[80px]">
                    <Switch checked={row.getValue('is_kit')} disabled />
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'exclude_from_pick_rate',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Excluded from Pricing" />,
            cell: ({ row }) => (
                <div className="z-99 w-[80px]">
                    <Switch checked={row.getValue('exclude_from_pick_rate')} disabled />
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'count_as_single_unit',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Count Kit as Single Unit for Invoicing" />,
            cell: ({ row }) => (
                <div className="z-99 w-[80px]">
                    <Switch checked={row.getValue('count_as_single_unit')} disabled />
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
    ];

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="grid grid-cols-4 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="col-span-3">
                                <h1 className="text-2xl font-bold text-rails-dark-blue">Products</h1>
                                <h2 className="text-xs text-rails-dark-blue">Manage products</h2>
                            </div>
                            <div className="container mx-auto text-right">
                                {!isLoading && !searchOpen && <Button onClick={() => setSearchOpen(true)}>Search for Products</Button>}
                            </div>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-5">
                            <div className="relative">
                                {searchOpen && (
                                    <div className="absolute inset-0 z-10 bg-white bg-opacity-75">
                                        <ProductSearch
                                            open={searchOpen}
                                            setOpen={setSearchOpen}
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            onSearchQuery={handleSearchQuery}
                                            resetTable={resetTable}
                                        />
                                    </div>
                                )}
                                <div className="container mx-auto py-5">
                                    {tableData && (
                                        <DataTable
                                            loading={isLoading}
                                            data={tableData}
                                            columns={columns}
                                            isMultiSelectRows={false}
                                            isDownloadable
                                            downloadData={tableData}
                                            tableName="packages"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            <ProductView open={open} setOpen={setOpen} selectedProduct={selectedProduct} />
        </div>
    );
};

export default Products;
