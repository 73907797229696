import * as z from 'zod';
import { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger, SheetFooter } from '../../common/sheet.tsx';
import LoadingSpinner from '../../common/loadingSpinner';
import ProductEditForm from './forms/productEditForm';
import { putInvoiceProductsProductIdPut, putKitItemsInvoiceProductsProductIdKitsPut } from '../../../client/services.gen.ts';
import { wrapperFunction } from '../../../client/wrapperFunction.ts';

const ProductEdit = ({ selectedProduct }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Schema for individual kit item
    const kitSchema = z.object({
        product: z.object({
            label: z.string().optional(),
            value: z.string().optional(), // Ensure this matches the format coming from the API
        }),
        quantity: z.number().min(1, 'Quantity must be at least 1').optional(),
    });

    // Schema for product edit form
    const productSchema = z
        .object({
            unit_charge: z.number().min(0.01).nullish(), // Ensuring the charge is a valid number
            exclude_from_pick_rate: z.boolean().nullish(),
            count_as_single_unit: z.boolean().nullish(),
            is_kit: z.boolean().nullish(),
            kit_items: z.array(kitSchema).optional(),
        })
        .refine(
            (input) => {
                if (!input.is_kit && input.kit_items && input.kit_items.length > 0) {
                    return false;
                }
                return true;
            },
            {
                message: 'Please set Is Kit to True since kit components are selected',
                path: ['is_kit'],
            },
        )
        .refine(
            (input) => {
                if (input.is_kit && (!input.kit_items || input.kit_items.length === 0)) {
                    return false;
                }
                return true;
            },
            {
                message: 'Please select kit components since Is Kit is set to True',
                path: ['kit'],
            },
        );

    // Initialize form with selected product data
    const transformedSelectedProduct = {
        ...selectedProduct,
        kit_items: selectedProduct.kit_items || [],
    };

    const methods = useForm({
        resolver: zodResolver(productSchema),
        defaultValues: transformedSelectedProduct,
    });

    const transformKit = (kitItems) =>
        kitItems
            ?.map((item) => {
                if (item?.product?.value && item.quantity > 0) {
                    return {
                        product_id: item.product.value, // Correctly use the product value as ID
                        quantity: item.quantity, // Ensure quantity is valid
                    };
                }
                return null; // Filter out invalid entries
            })
            .filter(Boolean); // Filter out null or undefined entries

    const onSubmit = async (values) => {
        setIsLoading(true);

        try {
            // Create a diff of changed values
            const diff = Object.fromEntries(Object.entries(values).filter(([k, v]) => selectedProduct[k] !== v));

            // Apply the transformKit function if kit_items have changed
            if ('kit_items' in diff) {
                diff.kit_items = transformKit(values.kit_items);
            }

            // Ensure unit_charge is properly converted to a number
            if ('unit_charge' in diff) {
                diff.unit_charge = Number(diff.unit_charge);
            }

            // Update the product details
            await wrapperFunction(putInvoiceProductsProductIdPut, { productId: selectedProduct.id, requestBody: diff });

            // Handle updating kit items if applicable
            if (values.is_kit && diff.kit_items?.length > 0) {
                await wrapperFunction(putKitItemsInvoiceProductsProductIdKitsPut, {
                    productId: selectedProduct.id,
                    requestBody: diff.kit_items, // Use the transformed kit items
                });
            } else if (!values.is_kit) {
                // If it's no longer a kit, delete the kit items
                await wrapperFunction(putKitItemsInvoiceProductsProductIdKitsPut, {
                    productId: selectedProduct.id,
                    requestBody: [], // Send an empty array to trigger kit item deletion
                });
            }

            setIsDialogOpen(false);
            window.location.reload();
        } catch (error) {
            console.error('Failed to update product:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button onClick={() => setIsDialogOpen(true)} className="ml-4">
                    Edit
                </Button>
            </SheetTrigger>
            {isDialogOpen && (
                <SheetContent position="right" size="lg">
                    <SheetHeader className="pb-36 overflow-y-auto h-full">
                        <SheetTitle className="text-3xl">Edit Product</SheetTitle>
                        <SheetDescription className="text-rails-dark-blue">
                            <FormProvider {...methods}>
                                <ProductEditForm selectedProduct={selectedProduct} />
                            </FormProvider>
                        </SheetDescription>
                    </SheetHeader>
                    <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                        <div>
                            <Button disabled={isLoading} onClick={methods.handleSubmit(onSubmit)} type="submit">
                                {isLoading ? (
                                    <>
                                        <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                                        Saving
                                    </>
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </div>
                    </SheetFooter>
                </SheetContent>
            )}
        </Sheet>
    );
};

export default ProductEdit;
