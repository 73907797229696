import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger, SheetFooter } from '../../common/sheet.tsx';
import InvoiceCreateFrom from './forms/invoiceCreateForm';
import useInvoiceApi from '../../../hooks/api/useInvoiceApi';
import LoadingSpinner from '../../common/loadingSpinner';

const InvoiceCreate = ({ customerData }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { createInvoice } = useInvoiceApi();

    const dropDownSchema = z.object({
        label: z.string(),
        value: z.string(),
    });

    const customerSchema = z.object({
        customer: dropDownSchema,
    });

    const formProps = useForm({
        resolver: zodResolver(customerSchema),
    });

    const { handleSubmit } = formProps;

    const onSubmit = (values) => {
        setIsLoading(true);

        createInvoice(values.customer).then((res) => {
            if (res.success) {
                setIsLoading(false);
                setIsDialogOpen(false);
                window.location.reload();
            }
        });
    };
    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button
                    onClick={() => {
                        setIsDialogOpen(true);
                    }}
                    size="sm"
                    className="ml-4 h-8 text-sm"
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Open Invoice
                </Button>
            </SheetTrigger>
            {isDialogOpen && (
                <SheetContent position="right" size="lg">
                    <SheetHeader className="pb-36 overflow-y-auto h-full">
                        <SheetTitle className="text-3xl">Create Invoice</SheetTitle>
                        <SheetDescription className="text-rails-dark-blue">
                            <InvoiceCreateFrom formProps={formProps} customerData={customerData} />
                        </SheetDescription>
                    </SheetHeader>
                    <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                        <div>
                            <Button disabled={isLoading} onClick={handleSubmit((e) => onSubmit(e))} type="submit">
                                {isLoading && (
                                    <>
                                        <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                                        Creating
                                    </>
                                )}
                                {!isLoading && <>Create</>}
                            </Button>
                        </div>
                    </SheetFooter>
                </SheetContent>
            )}
        </Sheet>
    );
};

export default InvoiceCreate;
