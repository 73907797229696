import { useState, useEffect } from 'react';

import Select from 'react-select';

import { Input } from '../../../common/input.tsx';
import { Form, FormField, FormItem, FormControl, FormMessage } from '../../../common/form.tsx';
import useInvoiceApi from '../../../../hooks/api/useInvoiceApi';

import LoadingSpinner from '../../../common/loadingSpinner';

const AddChargeForm = ({ formProps }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [invoiceChargeTypes, setInvoiceChargeTypes] = useState({});
    const { getInvoiceChargeTypes } = useInvoiceApi();

    useEffect(() => {
        getInvoiceChargeTypes().then((res) => {
            setInvoiceChargeTypes(
                res.data
                    .filter((item) => item.charge_level === 'invoice_level' && !item.name.toLowerCase().includes('special rule'))
                    .map((item) => ({ label: item.name, value: item.id })),
            );
            setIsLoading(false);
        });
    }, []);

    return (
        <>
            <LoadingSpinner loading={isLoading} />
            {!isLoading && (
                <Form {...formProps}>
                    <form className="space-y-8">
                        <div className="pt-4">
                            <div className="flex text-xl font-medium">Charge Name on Invoice</div>
                            <div className="mt-2">
                                <FormField
                                    control={formProps.control}
                                    name="charge_type_id"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Select
                                                    {...field}
                                                    options={invoiceChargeTypes}
                                                    placeholder="Charge Category"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DBF3D8',
                                                            primary: '#92BAA3',
                                                        },
                                                    })}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="pt-4">
                            <div className="flex text-xl font-medium">Total Charge Amount</div>
                            <FormField
                                control={formProps.control}
                                name="amount"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input className="mt-2" placeholder="Enter Total Charge Amount" type="number" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="pt-4">
                            <div className="flex text-xl font-medium">Total Quantity</div>
                            <FormField
                                control={formProps.control}
                                name="total_quantity"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input className="mt-2" placeholder="Enter Total Quantity" type="number" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="pt-4">
                            <div className="flex text-xl font-medium">Description</div>
                            <FormField
                                control={formProps.control}
                                name="description"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input className="mt-2" placeholder="Enter Charge Description" type="text" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </form>
                </Form>
            )}
        </>
    );
};

export default AddChargeForm;
