import * as z from 'zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger, SheetFooter } from '../../common/sheet.tsx';
import ReceivingFeeForm, { ReceivingChargeType } from './forms/receivingFeesForm';
import LoadingSpinner from '../../common/loadingSpinner';
import useReceivingApi from '../../../hooks/api/useReceivingApi';

const ReceivingFeesEdit = ({ selectedReceivingFee, customerData }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { getReceivingFee, updateReceivingFee } = useReceivingApi();

    const dropDownSchema = z.object({
        label: z.string(),
        value: z.string(),
    });

    const ReceivingFeeSchema = z.object({
        name: z.string().trim().min(3, { message: 'Required' }),
        all_customers: z.boolean(),
        customer_ids: z.array(dropDownSchema).optional(),
        charge_rate_type: dropDownSchema,
        charge_amount: z.coerce.number().nullish(),
    });

    const formProps = useForm({
        resolver: zodResolver(ReceivingFeeSchema),
        defaultValues: {
            ...selectedReceivingFee,
            customer_ids: selectedReceivingFee?.customers
                ? selectedReceivingFee?.customers.map((customer) => ({
                      label: customer.name,
                      value: customer.id,
                  }))
                : [],
            charge_rate_type: selectedReceivingFee?.charge_type ? ReceivingChargeType.find((unit) => unit.value === selectedReceivingFee?.charge_type) : {},
            charge_amount: selectedReceivingFee?.charge_amount,
        },
    });

    const { handleSubmit } = formProps;

    const onSubmit = (values) => {
        setIsLoading(true);

        updateReceivingFee({
            id: selectedReceivingFee.id,
            name: values.name,
            all_customers: values.all_customers,
            customer_ids: values.customer_ids.map((customer) => customer.value),
            charge_type: values.charge_rate_type.value,
            charge_amount: values.charge_amount,
            active: true,
        }).then((resp) => {
            if (resp.success) {
                getReceivingFee(resp.data).then(() => {
                    setIsLoading(false);
                    window.location.reload();
                });
            }
        });
    };

    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button
                    onClick={() => {
                        setIsDialogOpen(true);
                    }}
                    className="ml-4"
                >
                    Edit
                </Button>
            </SheetTrigger>
            {isDialogOpen && (
                <SheetContent position="right" size="lg">
                    <SheetHeader className="pb-36 overflow-y-auto h-full">
                        <SheetTitle className="text-3xl">Edit Receiving Fee</SheetTitle>
                        <SheetDescription className="text-rails-dark-blue">
                            <ReceivingFeeForm formProps={formProps} customerData={customerData} />
                        </SheetDescription>
                    </SheetHeader>
                    <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                        <div>
                            <Button disabled={isLoading} onClick={handleSubmit((e) => onSubmit(e))} type="submit">
                                {isLoading && (
                                    <>
                                        <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                                        Saving
                                    </>
                                )}
                                {!isLoading && <>Save</>}
                            </Button>
                        </div>
                    </SheetFooter>
                </SheetContent>
            )}
        </Sheet>
    );
};

export default ReceivingFeesEdit;
