import * as z from 'zod';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetFooter } from '../../common/sheet.tsx';
import AddLineChargeForm from './forms/invoiceAddLineChargeForm';
import LoadingSpinner from '../../common/loadingSpinner';
import useInvoiceApi from '../../../hooks/api/useInvoiceApi';

const InvoiceEditLineChargeView = ({ isOpen, setIsOpen, invoiceId, orderData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [invoiceChargeTypes, setInvoiceChargeTypes] = useState([]);
    const { createLineItemForInvoice, getInvoiceChargeTypes } = useInvoiceApi();

    const dropDownSchema = z.object({
        label: z.string(),
        value: z.string(),
    });

    useEffect(() => {
        getInvoiceChargeTypes().then((res) => {
            const filteredData = res.data
                .filter((item) => item.charge_level !== 'invoice_level' && !item.name.toLowerCase().includes('special rule'))
                .map((item) => ({ label: item.name, value: item.id, charge_level: item.charge_level }));
            setInvoiceChargeTypes(filteredData);
            setIsLoading(false);
        });
    }, []);

    // Define the schema with zod
    const invoiceChargeSchema = z.object({
        charge_type_id: dropDownSchema,
        order_number: z.string().nullable(),
        amount: z.coerce.number().min(0, { message: 'Required' }),
        quantity: z.coerce.number().min(0, { message: 'Required' }),
        description: z.string().nullable(),
    });

    // Set default values using the fetched invoiceData
    const defaultValues = {
        charge_type_id: {
            label: orderData?.charge_type_name || '',
            value: orderData?.charge_type_id || '',
        },
        order_number: orderData?.order_number || null,
        amount: orderData?.amount ? parseFloat(orderData.amount) : 0,
        quantity: orderData?.quantity ? parseFloat(orderData.quantity) : 0,
        description: null,
    };

    // Initialize the form with useForm and default values
    const formProps = useForm({
        resolver: zodResolver(invoiceChargeSchema),
        defaultValues,
    });

    const { handleSubmit, reset } = formProps;

    const onSubmit = (values) => {
        setIsLoading(true);

        // Find the selected charge type
        const selectedChargeType = invoiceChargeTypes.find((type) => type.value === values.charge_type_id.value);

        const payload = {
            amount: values.amount,
            charge_type_id: values.charge_type_id.value,
            invoice_id: invoiceId,
            description: values.description,
            quantity: values.quantity,
        };

        // Conditionally add order_id or package_id based on charge_level
        if (selectedChargeType) {
            if (selectedChargeType.charge_level === 'order_level') {
                payload.order_id = orderData.order_id;
            } else if (selectedChargeType.charge_level === 'package_level') {
                payload.package_id = orderData.package_id;
            }
        }

        createLineItemForInvoice(payload).then((res) => {
            if (res.success) {
                setIsLoading(false);
                window.location.reload();
            }
        });
    };

    useEffect(() => {
        reset(defaultValues); // Reset form with default values whenever invoiceData changes
    }, [reset]);

    return (
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
            <SheetContent position="right" size="lg">
                <SheetHeader className="pb-36 overflow-y-auto h-full">
                    <SheetTitle className="text-3xl">Add New Charge</SheetTitle>
                    <SheetDescription className="text-rails-dark-blue">Add new charge for order {orderData?.order_number}</SheetDescription>
                    <AddLineChargeForm formProps={formProps} invoiceChargeTypes={invoiceChargeTypes} />
                </SheetHeader>
                <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                    <div>
                        <Button disabled={isLoading} onClick={handleSubmit(onSubmit)} type="submit">
                            {isLoading ? (
                                <>
                                    <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                                    Updating
                                </>
                            ) : (
                                <>Update</>
                            )}
                        </Button>
                    </div>
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
};

export default InvoiceEditLineChargeView;
