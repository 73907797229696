import React, { useState, useEffect } from 'react';
import { DataTable } from '@/components/ui/datatable/data-table';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent} from '@/components/ui/dialog';
import { Plus } from 'lucide-react';
import { getIntegrationCarrierGet, postIntegrationCarrierPost, putIntegrationCarrierCarrierIdPut } from '@/client/services.gen';
import { wrapperFunction } from '@/client/wrapperFunction';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import { Form, FormField, FormItem, FormControl, FormMessage, FormLabel } from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

interface CarrierIntegration {
    id: string;
    carrier_name: string;
    carrier_connection_name: string;
    is_active: boolean;
    account_number: string;
    username: string;
    password: string;
    facility_id?: string;
}

const carrierSchema = z.object({
    carrier_name: z.string().min(1, "Carrier name is required"),
    carrier_connection_name: z.string().min(1, "Connection name is required"),
    is_active: z.boolean(),
    account_number: z.string().min(1, "Account number is required"),
    username: z.string().min(1, "Username is required"),
    password: z.string().min(1, "Password is required"),
    facility_id: z.string().optional(),
});

const CarrierIntegrations: React.FC = () => {
    const [carriers, setCarriers] = useState<CarrierIntegration[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedCarrier, setSelectedCarrier] = useState<CarrierIntegration | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    useEffect(() => {
        fetchCarriers();
    }, []);

    const fetchCarriers = async () => {
        setLoading(true);
        try {
            // @ts-ignore
            const response = await wrapperFunction(getIntegrationCarrierGet, {});
            setCarriers(response);
        } catch (err) {
            console.error('Failed to fetch carriers:', err);
            setError('Failed to fetch carriers');
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            accessorKey: 'carrier_name',
            header: 'Carrier Name',
            cell: ({ row }: { row: any }) => (
                <span 
                    className="w-[200px] font-medium hover:underline"
                    onClick={() => handleEditCarrier(row.original)}
                >
                    {row.original.carrier_name}
                </span>
            ),
        },
        { accessorKey: 'carrier_connection_name', header: 'Connection Name' },
        {
            accessorKey: 'is_active',
            header: 'Active',
            cell: ({ row }: { row: any }) => (
                <Switch checked={row.original.is_active} disabled />
            ),
        },
    ];

    const handleCreateCarrier = () => {
        setSelectedCarrier(null);
        setIsDialogOpen(true);
    };

    const handleEditCarrier = (carrier: CarrierIntegration) => {
        setSelectedCarrier(carrier);
        setIsDialogOpen(true);
    };

    const onSubmit = async (values: z.infer<typeof carrierSchema>) => {
        try {
            if (selectedCarrier) {
                // @ts-ignore
                await wrapperFunction(putIntegrationCarrierCarrierIdPut, { carrierId: selectedCarrier.id, requestBody: values });
                setSuccessMessage('Carrier updated successfully');
            } else {
                // @ts-ignore
                await wrapperFunction(postIntegrationCarrierPost, { requestBody: values });
                setSuccessMessage('Carrier created successfully');
            }
            setIsDialogOpen(false);
            fetchCarriers();
        } catch (err) {
            setError(selectedCarrier ? 'Failed to update carrier' : 'Failed to create carrier');
            console.error(err);
        }
    };

    const CarrierForm: React.FC<{ carrier?: CarrierIntegration }> = ({ carrier }) => {
        const form = useForm<z.infer<typeof carrierSchema>>({
            resolver: zodResolver(carrierSchema),
            defaultValues: carrier || {
                carrier_name: '',
                carrier_connection_name: '',
                is_active: true,
                account_number: '',
                username: '',
                password: '',
                facility_id: '',
            },
        });

        return (
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                    <FormField
                        control={form.control}
                        name="carrier_name"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Carrier Name</FormLabel>
                                <FormControl>
                                    <Input placeholder="Carrier Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="carrier_connection_name"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Connection Name</FormLabel>
                                <FormControl>
                                    <Input placeholder="Connection Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="is_active"
                        render={({ field }) => (
                            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                                <div className="space-y-0.5">
                                    <FormLabel className="text-base">Active</FormLabel>
                                </div>
                                <FormControl>
                                    <Switch
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="account_number"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Account Number</FormLabel>
                                <FormControl>
                                    <Input placeholder="Account Number" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="username"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Username</FormLabel>
                                <FormControl>
                                    <Input placeholder="Username" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Password</FormLabel>
                                <FormControl>
                                    <Input type="password" placeholder="Password" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="facility_id"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Facility ID</FormLabel>
                                <FormControl>
                                    <Input placeholder="Facility ID" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <Button type="submit">{carrier ? 'Update' : 'Create'} Carrier</Button>
                </form>
            </Form>
        );
    };

    const AddCarrierButton = (
        <Button onClick={handleCreateCarrier}>
            <Plus className="mr-2 h-4 w-4" /> Carrier
        </Button>
    );

    return (
        <div className="container mx-auto p-8">
            {carriers.length === 0 ? (
                <div className="flex flex-col items-center justify-center h-[calc(100vh-200px)]">
                    <h2 className="text-2xl font-semibold mb-4">Connect your first carrier</h2>
                    {AddCarrierButton}
                </div>
            ) : (
                <DataTable 
                    columns={columns} 
                    data={carriers} 
                    loading={loading}
                    ActionButton={AddCarrierButton}
                    showActionButtonInToolbar={true}
                />
            )}

            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogContent>
                    <h2 className="text-lg font-semibold">Carrier Integration</h2>
                    <CarrierForm carrier={selectedCarrier || undefined} />
                </DialogContent>
            </Dialog>

            {error && <p className="text-red-500 mt-4">{error}</p>}
            {successMessage && <p className="text-green-500 mt-4">{successMessage}</p>}
        </div>
    );
};

export default CarrierIntegrations;
