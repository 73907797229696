/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react';
import cronstrue from 'cronstrue';

import { SettingsIcon } from 'lucide-react';
import useInvoiceApi from '../../../hooks/api/useInvoiceApi.js';
import { Card, CardContent, CardHeader, CardTitle } from '../../common/card.tsx';
import ConfigDialog from './configDialog.jsx';
import LoadingSpinner from '../../common/loadingSpinner.jsx';
import { billingPeriodCutoffTime } from '../../../consts/vars.js';

const Configurations = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [defaultConfigData, setDefaultConfigData] = useState();
    const [allConfig, setAllConfig] = useState([]);
    const { getAllConfigs } = useInvoiceApi();

    const getDefaultObject = (array) => {
        const defaultObject = array.find((obj) => obj.default === true);
        return defaultObject || null;
    };

    const getNonDefaultObject = (array) => {
        const nonDefaultObject = array.filter((obj) => obj.default === false);
        return nonDefaultObject || null;
    };

    const fetchConfigs = async () => {
        setIsLoading(true);

        getAllConfigs().then((res) => {
            const defaultData = {
                invoice: getDefaultObject(res.data.invoice),
                chargetypes_packagelevel: getDefaultObject(res.data.chargetypes_packagelevel),
                chargetypes_invoicelevel: getDefaultObject(res.data.chargetypes_invoicelevel),
            };
            setDefaultConfigData(defaultData);

            /*
            const nonDefaultData = {
                invoice: getNonDefaultObject(res.data.invoice),
                chargetypes_packagelevel: getNonDefaultObject(res.data.chargetypes_packagelevel),
                chargetypes_invoicelevel: getNonDefaultObject(res.data.chargetypes_invoicelevel),
            };
            setAllConfig(nonDefaultData);
            */
            setIsLoading(false);
        });
    };

    useEffect(() => {
        fetchConfigs();
    }, []);

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">Invoicing Configurations</h1>
                            <h2 className="text-xs text-rails-dark-blue">Manage invoice delivery configurations.</h2>
                        </div>
                        <div className="mt-12 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="pt-4">
                                <div className="container mx-auto py-4">
                                    <LoadingSpinner isLoading={isLoading} />
                                    {!isLoading && (
                                        <>
                                            {/* <div className="flex justify-end pb-2">
                                                <Button>
                                                    <PlusCircleIcon className="h-4 w-4 mr-2" />
                                                    Custom Invoice Configuration
                                                </Button> 
                                            </div> */}
                                            <Card className="w-full">
                                                <CardHeader>
                                                    <CardTitle className="flex items-center">
                                                        Default Configurations
                                                        <SettingsIcon className="ml-auto h-5 w-5 hover:text-gray-500" onClick={() => setIsDialogOpen(true)} />
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardContent>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8 mt-3 font-semibold">Delivery Cadence</div>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8">
                                                        <div>
                                                            {defaultConfigData?.invoice?.cadence
                                                                ? cronstrue
                                                                      .toString(defaultConfigData?.invoice?.cadence, { verbose: true })
                                                                      .replace('Every minute, every hour', billingPeriodCutoffTime)
                                                                : '-'}
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8 mt-3 font-semibold">Billable Activity Based On</div>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8">
                                                        <div>
                                                            <div>{!defaultConfigData?.chargetypes_packagelevel?.date_ship_or_order && '-'}</div>
                                                            <div>
                                                                {defaultConfigData?.chargetypes_packagelevel?.date_ship_or_order &&
                                                                    (defaultConfigData?.chargetypes_packagelevel?.date_ship_or_order === 'ship' ? 'Shipment date' : 'Order date')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8 mt-3 font-semibold">Delivery Method</div>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8">
                                                        <div>{defaultConfigData?.invoice?.delivery_method === 'erp_quickbooks' ? 'Quickbooks' : '-'}</div>
                                                    </div>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8 mt-3 font-semibold">
                                                        Invoice-Level Charges Delivery Cadence
                                                    </div>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8">
                                                        <div>
                                                            {defaultConfigData?.chargetypes_invoicelevel?.cadence
                                                                ? cronstrue
                                                                      .toString(defaultConfigData.chargetypes_invoicelevel?.cadence, { verbose: true })
                                                                      .replace('Every minute, every hour', billingPeriodCutoffTime)
                                                                : '-'}
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                            {/* {defaultConfigData?.invoice && (
                                                <div className="mt-12">
                                                    <div className="font-semibold">Custom Non Invoice-Level Charges Configuration</div>
                                                    <InvoiceConfigOverrideTable configOverrides={allConfig?.invoice} fetchAllConfig={fetchConfigs} />
                                                </div>
                                            )}
                                            {defaultConfigData?.chargetypes_invoicelevel && (
                                                <div className="mt-12">
                                                    <div className="font-semibold">Custom Invoice-Level Charges Configuration</div>
                                                    <ChargeInvoiceConfigOverrideTable configOverrides={allConfig?.chargetypes_invoicelevel} fetchAllConfig={fetchConfigs} />
                                                </div>
                                            )} */}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                        {!isLoading && <ConfigDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} fetchConfigs={fetchConfigs} initialValues={defaultConfigData} />}
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Configurations;
